<!-----Start edit sites----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <form [formGroup]="editSiteForm">                          
                            <div class="form-group row">
                                <label for="CompanyName" class="col-sm-2 col-form-label">Company Name</label>
                                <div class="col-sm-4">
                                        <select id="CompanyName" class="form-control" formControlName="companyName">
                                            <option value="companyName">Company name</option>
                                            <option *ngFor="let company of companyNameArr" >{{company.companyName}}</option>
                                        </select>
                                </div>
                                <label for="email" class="col-sm-2 col-form-label">Email ID</label>
                                <div class="col-sm-4">
                                    <input type="text" id="email" class="form-control" placeholder="Enter mail Id"
                                        formControlName="email">
                                    <span class="error">
                                        <p *ngIf="editSiteForm.get('email').hasError('required') && (editSiteForm.get('email').dirty || editSiteForm.get('email').touched )"
                                            class="error" padding>*Please enter email id.</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="editSiteForm.get('email').hasError('pattern') && editSiteForm.get('email').dirty"
                                            class="error" padding>*Please enter valid email.</p>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="comanyAddress" class="col-sm-2 col-form-label">Site Location</label>
                                <div class="col-sm-4">
                                    <input type="text" id="comanyAddress" class="form-control"
                                        placeholder="Site location" formControlName="siteLocation">
                                    <span class="error">
                                        <p *ngIf="editSiteForm.get('siteLocation').hasError('required') && (editSiteForm.get('siteLocation').dirty || editSiteForm.get('siteLocation').touched )"
                                            class="error" padding>*Please enter site location.</p>
                                    </span>
                                </div>

                                <label for="comanyAddress" class="col-sm-2 col-form-label">Site Address</label>
                                <div class="col-sm-4">
                                    <input type="text" id="comanyAddress" class="form-control"
                                        placeholder="Site address" formControlName="siteAddress">
                                    <span class="error">
                                        <p *ngIf="editSiteForm.get('siteAddress').hasError('required') && (editSiteForm.get('siteAddress').dirty || editSiteForm.get('siteAddress').touched )"
                                            class="error" padding>*Please enter site address.</p>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="state" class="col-sm-2 col-form-label">State</label>
                                <div class="col-sm-4">
                                    <select id="city" class="form-control" formControlName="state"
                                    (change)="searchCity($event)">
                                    <option value="State">State</option>
                                    <option *ngFor="let state of stateArr" [value]="state?.name">{{state.name}}</option>
                                </select>
                           </div>
                                <label for="city" class="col-sm-2 col-form-label">City</label>
                                <div class="col-sm-4">
                                <select id="city" class="form-control" formControlName="city">
                                    <option value="city">City</option>
                                    <option *ngFor="let city of cityArr" [value]="city?.name">{{city.name}}</option>
                                </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="gst" class="col-sm-2 col-form-label">GISTN No</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="gst" class="form-control" placeholder="Enter GISTN no"
                                        style="border: 1px solid;" formControlName="gstNo" maxlength="16">
                                    <div class="input-group-append">
                                        <button class="btn" type="button"
                                            style="background-color: green;">Validate</button>
                                    </div>
                                    <span class="error">
                                        <p *ngIf="editSiteForm.get('gstNo').hasError('required') && (editSiteForm.get('gstNo').dirty || editSiteForm.get('gstNo').touched )"
                                            class="error" padding>*Please enter GSTIN number.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6"></div>
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4">
                                    <label for="gstfile" class="btn btn-success btn-block btn-outlined">GISTN
                                        Copy</label>
                                        <input type="file" id="gstfile" accept=".png, .jpg, .jpeg" style="display: none"
                                        class="form-control" (change)="uploadGst($event)">
                                        <img [src]="gstimageUrl" alt=""> 
                                </div>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label">
                            <button type="submit" class="btn btn-theme mr-3"
                                (click)="cancel()">Cancel</button>
                            <button type="submit" class="btn btn-theme"
                                (click)="update()">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!-----End edit sites----->
