<!------Start crushers and mining information---->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="padding-bottom: 1px;min-width:654px">
                    <div class="main-block-innner mb40 mt40">
                        <div class="add-store-block input-style" style="margin-top: -7%;">
                            <div class="form-group row ">
                               <div class="col-md-10">
                                    <ck-editor name="editor1" aria-placeholder="Description" debounce="500"
                                        [(ngModel)]="data.pageData" skin="moono-lisa" language="en" [fullPage]="true">
                                    </ck-editor>
                                </div>
                                <div class="col-md-2 ">
                                    <ui-switch labelOn="Enable" labelOff="Disable" [(ngModel)]="data.enable"
                                        [ngModelOptions]="{standalone: true}"
                                        (change)="onChangeCrushersAndMiningStatus($event)"></ui-switch>
                                </div>
                            </div>
                            <div class="text-center mt40">
                                <div class="row">
                                    <div class="col-md-10">
                                        <a *ngIf="!data.staticId" href="javascript:void(0)" class="btn btn-theme"
                                            (click)="saveCrusherAndMining()">Add</a>
                                        <a *ngIf="data.staticId" href="javascript:void(0)" class="btn btn-theme"
                                            (click)="updateCrusherAndMining()">Save</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------End crushers and mining information---->
