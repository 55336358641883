<!----Start view fleet owner ---->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <form [formGroup]="editForm">
                            <div class="form-group row">
                                <label for="Fname" class="col-sm-2 col-form-label">First Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Fname" class="form-control" placeholder="First name"
                                        formControlName="firstName">
                                </div>
                                <label for="Lname" class="col-sm-2 col-form-label">Last Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Lname" class="form-control" placeholder="Last name"
                                        formControlName="lastName">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Mobile" class="col-sm-2 col-form-label">Mobile No</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Mobile" class="form-control"
                                        placeholder="Enter Mobile Number" formControlName="phoneNo">
                                </div>
                                <label for="email" class="col-sm-2 col-form-label">Email ID</label>
                                <div class="col-sm-4">
                                    <input type="text" id="email" class="form-control" placeholder="Enter mail Id"
                                        formControlName="email">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="CompanyName" class="col-sm-2 col-form-label">Company Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="CompanyName" class="form-control" placeholder="Company name"
                                        formControlName="companyName">
                                </div>
                                <label for="comanyAddress" class="col-sm-2 col-form-label">Company Address</label>
                                <div class="col-sm-4">
                                    <input type="text" id="comanyAddress" class="form-control"
                                        placeholder="Company Address" formControlName="baseLocationAddress">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="state" class="col-sm-2 col-form-label">State</label>
                                <div class="col-sm-4">
                                    <select id="city" class="form-control" formControlName="state"
                                        (change)="searchCity($event)">
                                        <option value="">State</option>
                                        <option *ngFor="let state of stateArr" [value]="state?.name">{{state?.name}}
                                        </option>
                                    </select>
                                </div>
                                <label for="city" class="col-sm-2 col-form-label">City</label>
                                <div class="col-sm-4">
                                    <select id="city" class="form-control" formControlName="city">
                                        <option value="">City</option>
                                        <option *ngFor="let city of cityArr" [value]="city?.name">{{city.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="aadhar" class="col-sm-2 col-form-label">Aadhar No</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="aadhar" class="form-control" placeholder="Enter aadhar no"
                                        formControlName="aadharCardNo">
                                    <img *ngIf="aadharCardUrl" [src]="aadharCardUrl" alt="" class="view-img">
                                </div>
                                <label for="pan" class="col-sm-2 col-form-label">Pan Card</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="pan" class="form-control" placeholder="Enter Pan no"
                                        formControlName="panCardNo">
                                    <img *ngIf="panCardUrl" [src]="panCardUrl" alt="" class="view-img">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="gst" class="col-sm-2 col-form-label">GISTN No</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="gst" class="form-control" placeholder="Enter GISTN no"
                                        formControlName="gstinNo">
                                    <img *ngIf="gstinUrl" [src]="gstinUrl" alt="" class="view-img">
                                </div>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label text-center">
                            <button type="submit" class="btn btn-theme mr-3"
                                [routerLink]="['/list-of-fleet-owner']">Back</button>
                            <button type="submit" class="btn btn-theme mr-3" (click)="editFleetOwner()">edit</button>
                            <button type="submit" class="btn btn-theme" (click)="viewRoute()">View Routes</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!----End view fleet owner ---->
