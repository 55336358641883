<!-----Start section of sidebar --->
<div class="wrapper" *ngIf="isLoggedIn">
    <header class="header">
        <nav class="navbar clearfix">
            <div class="header-right-part">
                <div class="row" style="position: absolute; width: 100%;">
                    <div class="col-lg-2 col-md-3">
                        <img src="assets/img/logo.png" alt="icon" />
                    </div>
                    <div class="col-lg-3 col-md-2">
                        <button class="btn btn-toggle" type="button" (click)="head()">
                            <i class="fas fa-bars" aria-hidden="true"></i>
                        </button>
                        <button class="btn btn-outline-secondary btn-mobsearch" type="button">
                            <i class="fas fa-search" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-lg-5 col-md-5" style="-webkit-transform: scale(1.7,1);">
                        <h5 style="color: white;text-align: center;">{{ currentText }}</h5>
                    </div>
                    <div class="col-lg-2 col-md -2">
                        <div class="header-dropdown-styling">
                            <div class="dropdown">
                                <a class="dropdown-toggle" data-toggle="dropdown" id="dropdownMenuLink"
                                    aria-haspopup="true" aria-expanded="false">
                                    <img class="avtar-pic"
                                        src="{{profileData?.profilePic || 'assets/img/profile-img.jpg'}}" alt="">
                                </a>
                                <div class="dropdown-menu dropdown-menu-cust dropdown-menu-right"
                                    aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item" routerLink="/change-password">Change Password</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>

    <!--------- Start admin --------->
    <aside *ngIf="!flag" class="sidebar" aria-labelledby="sidebar for admin">
        <div class="sidebar-scroller">
            <div id="accordion" class="sidebar-menu">
                <div class="menu-box" style="width:100%;border-bottom: 1px solid grey;"
                    [ngClass]="{'active':currUrl == 'dashboard'}">
                    <div class="card-header clearfix" id="headingOne">
                        <a class="side_menu" [routerLink]="['/dashboard']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <i class="fa fa-tachometer" aria-hidden="true"></i>
                            </span>
                            <span class="side_page_name">Dashboard</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" style="width:100%;border-bottom: 1px solid grey;"
                    [ngClass]="{'active':currUrl == 'list-of-companies' || currUrl == 'list-of-sites'}">
                    <div class="card-header clearfix" id="headingOne">
                        <a class="btn side_menu" routerLinkActive="active" (click)="showText();" data-toggle="collapse"
                            href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            <span class="side_menu_icon">
                                <i class="fa fa-building" aria-hidden="true"></i>
                            </span>
                            <span class="side_page_name">Company Management</span>
                        </a>
                        <div class="collapse" id="collapseExample">
                            <div class="card card-body">
                                <a [routerLink]="['/list-of-companies']">Companies</a>
                                <a [routerLink]="['/list-of-sites']">Sites</a>
                                <a [routerLink]="['/list-of-company-user']">Company User Management</a>
                                <a [routerLink]="['/list-of-company-quote']">Quotes</a>
                                <a [routerLink]="['/list-of-company-booking']">My Booking</a>
                                <a [routerLink]="['/list-of-company-billing']">Billing</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="menu-box" style="width:100%;border-bottom: 1px solid grey;"
                    [ngClass]="{'active':currUrl == 'list-of-fleet-owner'}">
                    <div class="card-header clearfix" id="headingOne">
                        <a class="btn side_menu" routerLinkActive="active" (click)="showText();" data-toggle="collapse"
                            href="#collapseExampleone" role="button" aria-expanded="false"
                            aria-controls="collapseExample">
                            <span class="side_menu_icon">
                                <img src="assets/img/Fleet.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Fleet Management</span>
                        </a>
                        <div class="collapse" id="collapseExampleone">
                            <div class="card card-body">
                                <a [routerLink]="['/list-of-fleet-owner']">Fleet Owner</a>
                                <a [routerLink]="['/list-of-truck']">Truck</a>
                                <a [routerLink]="['/list-of-bid-by-fleet-owner']">Bid</a>
                                <a [routerLink]="['/list-of-fleet-owner-booking']">Booking</a>
                                <a [routerLink]="['/list-of-fleet-owner-billing']">Billing</a>
                                <a [routerLink]="['/list-of-driver']">Driver</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="menu-box" style="width:100%;border-bottom: 1px solid grey;"
                    [ngClass]="{'active':currUrl == 'list-of-supplier'}">
                    <div class="card-header clearfix" id="headingOne">
                        <a class="btn side_menu" routerLinkActive="active" (click)="showText();" data-toggle="collapse"
                            href="#collapseExampleto" role="button" aria-expanded="false"
                            aria-controls="collapseExample">
                            <span class="side_menu_icon">
                                <img src="assets/img/Supplier.png" alt="icon" />
                            </span>
                            <span class="side_page_name"> Supplier Management</span>
                        </a>
                        <div class="collapse" id="collapseExampleto">
                            <div class="card card-body">
                                <a [routerLink]="['/list-of-supplier']">Supplier</a>
                                <a [routerLink]="['/list-of-supplier-booking']">Company Booking</a>
                                <a [routerLink]="['/list-of-supplier-quote']">Company Quotes</a>
                                <a [routerLink]="['/list-of-supplier-billing']">Company Billing</a>
                                <a [routerLink]="['/list-of-bid-by-fleet-owner-supplier']">Fleet Owner Bid</a>
                                <a [routerLink]="['/list-of-fleet-owner-booking-supplier']">Fleet Owner Booking</a>
                                <a [routerLink]="['/list-of-fleet-owner-billing-supplier']">Fleet Owner Billing</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="menu-box" style="width:100%;" [ngClass]="{'active':currUrl == 'truck-type'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/truck-type']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <i class="fa fa-truck" aria-hidden="true"></i>
                            </span>
                            <span class="side_page_name">Add Truck Type</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" [ngClass]="{'active':currUrl == 'materials'}" style="width:100%;">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/materials']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/Material.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Add Material</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" style="width:100%;" [ngClass]="{'active':currUrl == 'thermal-plants'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/thermal-plants']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/Plants.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Thermal Plants</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" style="width:100%;border-bottom: 1px solid grey;"
                    [ngClass]="{'active':currUrl == 'crushers-and-mining'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/crushers-and-mining']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/Crushers.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Crushers And Mining Info</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" style="width:100%;border-bottom: 1px solid grey;"
                    [ngClass]="{'active':currUrl == 'signup-data' || currUrl == 'AboutUs' ||  currUrl == 'TermsAndCondition'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/signup-data']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <i class="fa fa-sign-in" aria-hidden="true"></i>
                            </span>
                            <span class="side_page_name">Signup Data</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" style="width:100%;border-bottom: 1px solid grey;"
                    [ngClass]="{'active':currUrl == 'contact-us' || currUrl == 'terms-and-conditions' || currUrl == 'privacy-policy'}">
                    <div class="card-header clearfix" id="headingOne">
                        <a class="btn side_menu" routerLinkActive="active" (click)="showText();" data-toggle="collapse"
                            href="#collapseExamplethree" role="button" aria-expanded="false"
                            aria-controls="collapseExample">
                            <span class="side_menu_icon">
                                <i class="fa fa-globe" aria-hidden="true"></i>
                            </span>
                            <span class="side_page_name">Others</span>
                        </a>
                        <div class="collapse" id="collapseExamplethree">
                            <div class="card card-body">
                                <a [routerLink]="['/contact-us']">Add Contact Us Mobile No And E-mail ID</a>
                                <a [routerLink]="['/terms-and-conditions']">Terms And Conditions</a>
                                <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="menu-box" style="width:100%;">
                    <div class="card-header clearfix">
                        <a class="side_menu" href="#signout_modal" data-toggle="modal">
                            <span class="side_menu_icon">
                                <i class="fa fa-sign-out" aria-hidden="true"></i>
                            </span>
                            <span class="side_page_name">Logout</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </aside>
</div>
<!------End admin----->
<!-----End section of sidebar --->

<!--Start Logout Modal -->
<div class="modal fade global-modal reset-modal" id="signout_modal">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <div>
                <div class="modal-body  text-center">
                    <div class="row align-items-center modal_flax_height">
                        <div class="col">
                            <div class="box-title mb40 d-inline-block">
                                <i class="fas fa-power-off off-icon" aria-hidden="true"></i>
                                <p class="mt40">Are you sure you want <br>to logout?</p>
                            </div>
                            <div class="text-center">
                                <a class="btn btn-theme" (click)="onLogout()">Yes</a> &nbsp; &nbsp; &nbsp;
                                &nbsp;
                                <a class="btn btn-theme" data-dismiss="modal">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--End Logout Modal -->
