<!------Start reset password ---->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
                                <div class="row mt-5 container container-fluid" style="position: fixed;left: 38%;">
                                    <form [formGroup]="resetPasswordForm">
                                        <div class="form-row">
                                            <div class="col-sm-4">
                                                <div>
                                                    <select type="text" class="form-control selectBox"
                                                        formControlName='role'>
                                                        <option class="fa fa-caret-down" selected>User Type</option>
                                                        <option *ngFor="let item of roleArray" class="fa fa-caret-down"
                                                            [value]="item">{{item}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div style="width: 155px;">
                                                    <input type="tel" class="form-control input-field" placeholder=""
                                                        formControlName="mobileNo">
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div *ngIf="resetParamData.role !== 'DRIVER'">
                                                    <input type="email" class="form-control input-field" placeholder=""
                                                        formControlName="email">
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label">
                                                <button type="submit" class="btn btn-theme mr-3"
                                                    (click)="back()">Back</button>
                                                <button type="submit" class="btn btn-theme"
                                                    (click)="ResetPassword()">Reset Password</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------End reset password ---->
