<!------Start delete sites---->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <form [formGroup]="viewSiteForm">
                            <div class="form-group row">
                                <label for="Fname" class="col-sm-2 col-form-label">Company Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Fname" class="form-control" placeholder="Company name"
                                        formControlName="companyName" disabled>
                                </div>
                                <label for="Lname" class="col-sm-2 col-form-label">Address</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Lname" class="form-control" placeholder="Address"
                                        formControlName="address" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="city" class="col-sm-2 col-form-label">City</label>
                                <input type="text" id="city" class="form-control"
                                placeholder="City" formControlName="city" disabled>

                                <label for="state" class="col-sm-2 col-form-label">State</label>
                                <div class="col-sm-4">
                                <input type="text" id="state" class="form-control"
                                placeholder="State" formControlName="state" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="gst" class="col-sm-2 col-form-label">GISTN No</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="gst" class="form-control" placeholder="Enter GISTN no"
                                        style="border: 1px solid;" formControlName="gstNo" maxlength="16" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6"></div>
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4">
                                    <img [src]="gstimageUrl" alt=""> 
                                </div>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label">
                            <button type="submit" class="btn btn-theme mr-3"
                            (click)="cancel()">Cancel</button>
                            <button type="submit" class="btn btn-theme deleye-btn"
                                (click)="delete()" >Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------End delete sites---->

<!------Start delete modal----->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Delete Site </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to delete this Site?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteSite('true')"
                                        data-dismiss="modal"
                                        style="min-width:100px;background-color: black;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="min-width: 100px;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!------End delete modal----->
