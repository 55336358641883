<!------ Start sites section in company management ----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
<!------- Start Search section and search by company name, location, state, city and email -------->
                                <form [formGroup]="userForm">
                                <div class="row">
                                    <div class="col-lg-2  pl-0 mt-4">
                                        <label>Company Name</label><br>
                                        <select id="CompanyName" class="selectBox form-control" (change)="searchLocation($event)" formControlName="companyName">
                                            <option value="">Company name</option>
                                            <option *ngFor="let company of companyNameArr" value={{company.id}}>{{company.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2  pl-0 mt-4">
                                        <label>Location</label><br>
                                        <select class="selectBox form-control" formControlName="location">
                                            <option value="">Location</option>
                                            <option *ngFor="let site of siteArr" value={{site.id}}>{{site.address}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2  pl-0 mt-4">
                                        <label>State</label><br>
                                        <select id="city" class="selectBox form-control" 
                                        (change)="searchCity($event)" formControlName="state">
                                        <option value="State">State</option>
                                        <option *ngFor="let state of stateArr">{{state.name}}</option>
                                    </select>
                                    </div>
                                    <div class="col-lg-2  pl-0 mt-4">
                                        <label>City</label><br>
                                        <select id="city" class="selectBox form-control" formControlName="city">
                                            <option value="city">City</option>
                                            <option *ngFor="let city of cityArr">{{city.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2  pl-0 mt-4">
                                        <label>Email</label><br>
                                        <input type="text" id="mobile" class="selectBox form-control"
                                        placeholder="Email" formControlName="email" >
                                        <span class="error">
                                            <p *ngIf="userForm.get('email').hasError('pattern') && userForm.get('email').dirty"
                                                class="error" padding>*Please enter valid email.</p>
                                        </span>
                                    </div>

                                    <div class="col-lg-2 mt-4 p-0">
                                        <label>&nbsp;</label><br>
                                        <button type="button" class="search-btn" (click)="search()">Search</button>
                                    </div>
                                </div>
                                <div class="row  d-flex justify-content-end w_100 mt-3 mb-3">
                                    <i class='fas fa-file-csv mr-1' style='font-size:35px;color:green'
                                        (click)="ExportToCsv()"></i>
                                    <button type="button" class="search-btn" (click)="reset()">Reset</button>
                                    <button type="button" class="btn-add" (click)="addSite()">Add</button>
                                </div>
                                </form>
<!------- End Search section and search by company name, location, state, city and email -------->

<!------- Start list of sites ------->
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Company Name</th>
                                                <th scope="col">Site Address</th>
                                                <th scope="col">Site Location</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">City</th>
                                                <th scope="col">State</th>
                                                <th scope="col">GSTIN</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of listing| paginate: { 
                                                itemsPerPage: itemsPerPage,
                                                currentPage: currentPage,
                                                totalItems: totalRecords };let i = index ">
                                                <td>{{item?.companyName}}</td>
                                                <td>{{item?.siteAddress}}</td>
                                                <td>{{item?.locationAddress}}</td>
                                                <td>{{item?.email}}</td>
                                                <td>{{item?.city}}</td>
                                                <td>{{item?.state}}</td>
                                                <td>{{item?.gstinNo}}</td>
                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <a tooltip="View"><i class="fa fa-eye" (click)="siteDetails(item?.siteId)"></i></a>
                                                    <a tooltip="Delete"><i class="fa fa-trash" (click)="deleteSite(item?.siteId)"></i></a>
                                                    <a *ngIf="item?.isEnable == true" tooltip="Block" (click)="openModal('false',item?.siteId)"><i class="fa fa-ban" style="color: green;"></i></a>
                                                    <a *ngIf="item?.isEnable == false" tooltip="Active" (click)="openModal('true',item?.siteId)"><i class="fa fa-ban" style="color: red;"></i></a>
                                                </td>
                                            </tr>                                                     
                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                            <tr>
                                                <td colspan="13" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
<!------- End list of sites ------->

<!--------Start pagination section for list of sites------->
                                <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                    *ngIf="listing?.length">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
<!--------End pagination section for list of sites------->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------ End sites section in company management ----->

<!--  Start block modal-->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Block Site </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to block this site?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 "
                                        style="width: 25%;background-color: black;" (click)="blockSite()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!--  End block modal-->

<!-- Start active modal -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Active Site </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to active this site?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        (click)="blockSite()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End active modal -->
