<!----Start view company user---->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <form [formGroup]="viewCompanyUserForm">
                            <div class="form-group row">
                                <label for="Fname" class="col-sm-2 col-form-label">First Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Fname" class="form-control" placeholder="First name" formControlName="firstName" disabled>
                                </div>
                                <label for="Lname" class="col-sm-2 col-form-label">Last Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Lname" class="form-control" placeholder="Last name" formControlName="lastName" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Mobile" class="col-sm-2 col-form-label">Mobile No</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Mobile" class="form-control"
                                        placeholder="Enter Mobile Number" formControlName="phoneNo" disabled>
                                </div>
                                <label for="email" class="col-sm-2 col-form-label">Email ID</label>
                                <div class="col-sm-4">
                                    <input type="text" id="email" class="form-control" placeholder="Enter mail Id" formControlName="email" disabled>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="CompanyName" class="col-sm-2 col-form-label">Company Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="CompanyName" class="form-control" placeholder="Company name" formControlName="companyName" disabled>
                                </div>
                                <label for="state" class="col-sm-2 col-form-label">Site</label>
                                <div class="col-sm-4">
                                    <input type="text" id="CompanyName" class="form-control" placeholder="Site" formControlName="site" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="state" class="col-sm-2 col-form-label">User Type</label>
                                <div class="col-sm-4">
                                    <input type="text" id="CompanyName" class="form-control" placeholder="Site" formControlName="userType" disabled>
                                </div>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label">
                            <button (click)="back()" type="submit" class="btn btn-theme mr-3">Back</button>
                            <button (click)="edit()" type="submit" class="btn btn-theme">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!----End view company user---->
