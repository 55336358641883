<!-----Start supplier section in supplier management ----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">

<!------- Start search section and search by supplier name, location, state, city and mobile ---->
                                <div class="row">
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>Supplier Name</label><br>
                                        <input type="text" class="form-control input-field" placeholder="Supplier Name"
                                            [(ngModel)]="firstName">
                                    </div>

                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>Location</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="location">
                                            <option value="">Location</option>
                                            <option *ngFor="let item of supplierNameArray"
                                                [value]="item?.baseLocationAddress">{{item?.baseLocationAddress}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>State</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="state"
                                            (change)="searchCity($event)">
                                            <option value="">State</option>

                                            <option *ngFor="let item of stateArr" [value]="item?.name">{{item?.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>City</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="city"
                                            [attr.disabled]="state ? null : '' ">

                                            <option value="">City</option>
                                            <option *ngFor="let item of cityArr" [value]="item?.name">{{item?.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>Mobile</label><br>
                                        <input type="text" class="form-control input-field"
                                            (keypress)="service.numberOnly($event)" placeholder="Mobile no"
                                            [(ngModel)]="phoneNo">
                                    </div>
                                </div>
                                <div class="row  d-flex justify-content-end w_100 mt-3 mb-3">
                                    <i class='fas fa-file-csv mr-1' style='font-size:35px;color:green; cursor: pointer;'
                                        (click)="exportToCsv()" aria-hidden="true"></i>
                                    <button type="button" class="search-btn" (click)="searchItem()">Search</button>
                                    <button type="button" class="search-btn" (click)="reset()">Reset</button>

                                    <button type="button" class="btn-add" [routerLink]="['/add-supplier']">Add</button>
                                </div>
<!------ End  search section and search by supplier name, location, state, city and mobile ---->

<!------- Start list of supplier ------>                
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Supplier Name</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Mobile No</th>
                                                <th scope="col">E-Mail</th>
                                                <th scope="col">City</th>
                                                <th scope="col">State</th>
                                                <th scope="col">GSTIN</th>
                                                <th scope="col">Date Of Creation</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let item of listing| paginate: {
                                    itemsPerPage: itemsPerPage,
                                    currentPage: currentPage,
                                    totalItems: totalItems };let i = index ">
                                                <!-- <td>{{item?.firstName}} {{item?.lastName}}</td> -->
                                                <td>{{item?.companyName}}</td>
                                                <td>{{item?.baseLocationAddress}}</td>
                                                <td>{{item?.phoneNo}}</td>
                                                <td>{{item?.email}}</td>
                                                <td>{{item?.city}}</td>
                                                <td>{{item?.state}}</td>
                                                <td>{{item?.gstInNo}}</td>
                                                <td>{{item?.createTime | date}}</td>

                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <a tooltip="View" [routerLink]="['/view-supplier',item?.userId]"><i
                                                            class="fa fa-eye" title="View" aria-hidden="true"></i></a>
                                                    <a tooltip="Delete" [routerLink]="['/delete-supplier',item?.userId]"><i
                                                            class="fa fa-trash" title="delete"
                                                            aria-hidden="true"></i></a>

                                                    <a *ngIf="item?.userStatus == 'ACTIVE'" tooltip="Block"
                                                        (click)="openblockModal('BLOCK',item?.userId)"><i
                                                            class="fa fa-ban" title="deactivate" style="color: green;"
                                                            aria-hidden="true"></i></a>
                                                    <a *ngIf="item?.userStatus == 'BLOCK'" tooltip="Active"
                                                        (click)="openblockModal('ACTIVE',item?.userId)"><i
                                                            class="fa fa-ban" title="activate" style="color: red;"
                                                            aria-hidden="true"></i></a>
                                                    <a tooltip="Reset Password"
                                                        (click)="resetPassword(item?.userId, item?.phoneNo, item?.email)"><i
                                                            class="fa fa-unlock-alt" aria-hidden="true"
                                                            title="Reset Password"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                            <tr>
                                                <td colspan="13" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
<!------- End list of supplier ------>   

<!------- Start pagination section for list of supplier ------>                
                                <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                    *ngIf="totalItems > 10">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
<!------- End pagination section for list of supplier ------>                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!-----End supplier section in supplier management ----->

<!--  Start delete modal -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Delete User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()"
                                        data-dismiss="modal"
                                        style="min-width:100px;background-color: black;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="min-width: 100px;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!--  End delete modal -->

<!--  Start block modal-->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Block Supplier </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to block<br> this Supplier?</p>
                                <div class="mt-3">
                                    <button type="submit" class="btn btn-theme mr-3 " (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-theme" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!--  End block modal-->

<!--  Start active modal -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Active Supplier </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to active<br> this Supplier?</p>
                                <div class="mt-3">
                                    <button type="submit" class="btn btn-theme mr-2" (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-theme" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!--  End active modal -->
