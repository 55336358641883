<!----Start delete company---->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <form [formGroup]="deleteCompanyForm">
                            <div class="form-group row">
                                <label for="Fname" class="col-sm-2 col-form-label">First Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Fname" class="form-control" placeholder="First name"
                                        formControlName="firstName" disabled>                                
                                </div>
                                <label for="Lname" class="col-sm-2 col-form-label">Last Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Lname" class="form-control" placeholder="Last name"
                                        formControlName="lastName" disabled>                                  
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Mobile" class="col-sm-2 col-form-label">Mobile No</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Mobile" class="form-control"
                                        placeholder="Enter mobile number" formControlName="phoneNo" disabled>                                  
                                </div>
                                <label for="email" class="col-sm-2 col-form-label">Email ID</label>
                                <div class="col-sm-4">
                                    <input type="text" id="email" class="form-control" placeholder="Enter mail Id"
                                        formControlName="email" disabled>                                  
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="CompanyName" class="col-sm-2 col-form-label">Company Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="CompanyName" class="form-control" placeholder="Company name"
                                        formControlName="companyName" disabled>
                                </div>
                                <label for="comanyAddress" class="col-sm-2 col-form-label">Company Address</label>
                                <div class="col-sm-4">
                                    <input type="text" id="comanyAddress" class="form-control"
                                        placeholder="Company address" formControlName="companyAddress" disabled>                                   
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="state" class="col-sm-2 col-form-label">State</label>
                                <div class="col-sm-4">
                                    <input type="text" id="state" class="form-control"
                                    placeholder="State" formControlName="state" disabled>
                                </div>
                                <label for="city" class="col-sm-2 col-form-label">City</label>
                                <div class="col-sm-4">
                                    <input type="text" id="city" class="form-control"
                                    placeholder="City" formControlName="city" disabled>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="aadhar" class="col-sm-2 col-form-label">Aadhar No</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="aadhar" class="form-control" placeholder="Enter aadhar number"
                                         formControlName="aadhaarNo"  maxlength="12" disabled>                                
                                </div>

                                <label for="pan" class="col-sm-2 col-form-label">Pan Card</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="pan" class="form-control" placeholder="Enter PAN number"
                                         formControlName="panCard" maxlength="10" disabled>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="gst" class="col-sm-2 col-form-label">GISTN No</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="gst" class="form-control" placeholder="Enter GISTN no"
                                         formControlName="gstNo" maxlength="16" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label"></label>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label" >
                            <button type="submit" class="btn btn-theme mr-3"
                            (click)="cancel()">Cancel</button>
                            <button type="submit" class="btn btn-theme deleye-btn"
                                (click)="delete()">Delete</button>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!----End delete company---->

<!-----Start delete modal---->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Delete Company </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to delete this Company?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteCompany()"
                                        data-dismiss="modal"
                                        style="min-width:100px;background-color: black;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="min-width: 100px;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-----End delete modal---->
