<!--------Start view driver ------->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <form [formGroup]="editForm">
                            <div class="form-group row">
                                <label for="Fname" class="col-sm-2 col-form-label">Fleet Owner Name</label>
                                <div class="col-sm-4">
                                    <select name="" id="" class="form-control" formControlName="companyName">
                                        <option value="">Fleet Owner Name</option>
                                        <option *ngFor="let item of listing" [value]="item.userId">{{item.companyName}}
                                        </option>
                                    </select>
                                </div>
                                <label for="Lname" class="col-sm-2 col-form-label">Driver First Name</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="Driver First name"
                                        formControlName="firstName">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Mobile" class="col-sm-2 col-form-label">Driver Last Name</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="Driver Last Name"
                                        formControlName="lastName">
                                </div>
                                <label for="email" class="col-sm-2 col-form-label">Driver Mobile No.</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="Driver Mobile No."
                                        formControlName="phoneNo">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="CompanyName" class="col-sm-2 col-form-label">Driver Licence No.</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="Driver Licence No."
                                        formControlName="drivingLicenceNo">
                                </div>
                                <label for="comanyAddress" class="col-sm-2 col-form-label">Driver Aadhaar No.</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="Driver Aadhaar No."
                                        formControlName="aadharCardNo">
                                </div>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label">
                            <button type="submit" class="btn btn-theme mr-3" (click)="back()">Back</button>
                            <button type="submit" class="btn btn-theme" (click)="editDriver()">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!--------End view driver ------->
