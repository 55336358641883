<!-------Start routes section in fleet management ------>
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
                                <form [formGroup]="addRouteForm">
<!-----Start search section and search by state name and city name---->
                                    <div class="row  d-flex justify-content-end w_100 mt-3">
                                        <div class="col-lg-3 mt-5">
                                            <select id="city" class="form-control" formControlName="state"
                                                (change)="searchCity($event)">
                                                <option value="">State</option>
                                                <option *ngFor="let state of stateArr" [value]="state?.name">
                                                    {{state?.name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3 mt-5">
                                            <select id="city" class="form-control" formControlName="city">
                                                <option value="">City</option>
                                                <option value="ALLCITY">All City</option>
                                                <option *ngFor="let city of cityArr" [value]="city?.name">{{city?.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3 mt-5">
                                            <button type="button" class="search-btn"
                                                style="margin-left: 40%;color: white;width: 66%;height: 101%;"
                                                [disabled]="addRouteForm.invalid" (click)="submit()">Add</button>
                                        </div>
                                        <div class="col-lg-3 mt-5">
                                            <button type="button" class="search-btn"
                                                style="margin-left: 40%;color: white;width: 66%;height: 101%;"
                                                (click)="back()">Done</button>
                                        </div>
                                    </div>
<!-----End search section and search by state name and city name---->
                                </form>

<!-----Start list of routes---->
                                <div class="table-responsive mt-5">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Fleet Owner ID</th>
                                                <th scope="col">State</th>
                                                <th scope="col">Cities</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let item of routeDataArray | paginate: { itemsPerPage: itemsPerPage,currentPage: pageNumber,totalItems: totalRecords };let i = index ">
                                                <td>{{id}}</td>
                                                <td>{{item?.state}}</td>
                                                <td>{{item?.cities}}</td>
                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <a tooltip="Delete" (click)="openModal('DELETE',item?.routeId)"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="routeDataArray?.length == 0">
                                            <tr>
                                                <td colspan="13" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
<!-----End list of routes---->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!-------End routes section in fleet management ------>

<!-- Start delete modal-->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Delete Route </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to delete this route?</p>
                                <div>
                                    <button type="submit" class="btn btn-theme mr-2" (click)="deleteRouteOfUser()"
                                        data-dismiss="modal"
                                        style="min-width:100px;background-color: black;">Yes</button>
                                    <button type="button" class="btn btn-theme" data-dismiss="modal"
                                        style="min-width: 100px;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End delete modal-->
