<!-----Start view fleet owner booking supplier ---->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Supplier Name :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.supplierName || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Material :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class=" outup-label col-form-label">{{bookingItems?.material || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Mobile" class="col-sm-2 col-form-label">Weight :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class=" outup-label col-form-label">{{bookingItems?.weight || 'N/A'}}</label>
                            </div>
                            <label for="email" class="col-sm-2 col-form-label">Booking Date :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.createTime |date}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Amount :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.bidAmount || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Invoice Date :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.inVoiceDateForSupplier|date}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">PO Number :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.poNumber || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Invoice Number :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.inVoiceNoForSupplier || 'N/A'}}</label>
                            </div>
                        </div>
                        <hr>

                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Vehicle Number :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.truckNumber || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Vehicle Type :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.truckType || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Driver Name :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.driverName || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Driver Mobile No :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.driverMobileNo || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Loaded Weight :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.loadedWeight || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Unloaded Weight :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.unLoadedWeight || 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Receiver Name :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.receiverName || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Comments :</label>
                            <div class="col-sm-4">
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                    readonly>{{bookingItems?.comments||"N/A"}}</textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Mobile" class="col-sm-2 col-form-label">Pick up Site :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.location || 'N/A'}}</label>
                            </div>
                            <label for="email" class="col-sm-2 col-form-label">Drop Site :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                    class="outup-label col-form-label">{{bookingItems?.dropLocation || 'N/A'}}</label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label">
                            <button type="submit" class="btn btn-theme" (click)="close()">Close</button>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!-----End view fleet owner billing supplier ---->
