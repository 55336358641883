<!------ Start section of list of driver in fleet management----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
<!------- Start Search section and search by fleet owner name ,driver first name, driver last name and mobile -------->
                                   <div class="row">
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>Fleet Owner Name</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="companyName">
                                            <option value="">Select Fleet Owner</option>
                                            <option *ngFor="let item of fleetOnwerCompanyNameArray"
                                                [value]="item?.companyName">{{item?.companyName}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>Driver First Name</label><br>
                                        <input type="text" class="form-control input-field"
                                            placeholder="Driver First Name" [(ngModel)]="firstName">
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>Driver Last Name</label><br>
                                        <input type="text" class="form-control input-field"
                                            placeholder="Driver Last Name" [(ngModel)]="lastName">
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>Mobile</label><br>
                                        <input type="text" class="form-control input-field" placeholder="Mobile"
                                            [(ngModel)]="phoneNo" maxlength="20">
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>&nbsp;</label><br>
                                        <button type="button" class="search-btn" (click)="search()">Search</button>
                                    </div>
                                </div>
                                <div class="row  d-flex justify-content-end w_100 mt-3 mb-3">
                                    <i class='fas fa-file-csv mr-1' style='font-size:35px;color:green; cursor: pointer;'
                                        (click)="exportToCsv()"></i>
                                    <button type="button" class="search-btn" (click)="reset()">Reset</button>
                                    <button type="button" class="btn-add" (click)="addDriver()">Add</button>
                                </div>
<!------- End Search section and search by fleet owner name ,driver first name, driver last name and mobile -------->
<!------- Start list of driver ------->
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Fleet Owner Name</th>
                                                <th scope="col">Driver First Name</th>
                                                <th scope="col">Driver Last Name</th>
                                                <th scope="col">Mobile No</th>
                                                <th scope="col">Aadhar No</th>
                                                <th scope="col">Date Of Creation</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let item of listing| paginate: { itemsPerPage: itemsPerPage,currentPage: currentPage,totalItems: totalItems };let i = index ">
                                                <td>{{item?.companyName}}</td>
                                                <td>{{item?.firstName}}</td>
                                                <td>{{item?.lastName}}</td>
                                                <td>{{item?.phoneNo}}</td>
                                                <td>{{item?.aadharCardNo}}</td>
                                                <td>{{item?.createTime | date:'medium'}}</td>
                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <a tooltip="View" (click)="viewDriver(item?.userId)"><i class="fa fa-eye"
                                                            aria-hidden="true"></i></a>
                                                    <a tooltip="Delete" (click)="deleteDriver(item?.userId)"><i class="fa fa-trash"
                                                            aria-hidden="true"></i></a>
                                                    <a *ngIf="item?.userStatus == 'ACTIVE'" tooltip="Block"
                                                        (click)="openModal('BLOCK',item?.userId)">
                                                        <i class="fa fa-ban" style="color: green;"
                                                            aria-hidden="true"></i></a>
                                                    <a *ngIf="item?.userStatus == 'BLOCK'" tooltip="Active"
                                                        (click)="openModal('ACTIVE',item?.userId)">
                                                        <i class="fa fa-ban" aria-hidden="true"></i></a>
                                                    <a tooltip="Reset Password"
                                                        (click)="resetPassword(item?.userId, item?.phoneNo, item?.email)">
                                                        <i class="fa fa-unlock-alt" aria-hidden="true"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                            <tr>
                                                <td colspan="13" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
<!------- End list of driver ------->
<!--------Start pagination section for list of driver------->
                                <div *ngIf="totalItems > 10" class="custom-pagination mt20 "
                                    style="display: flex; justify-content:flex-end">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
<!--------End pagination section for list of driver------->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------ End section of list of driver in fleet management----->

<!-- Start block_modal  -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Block User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to block <br>this user?</p>
                                <div class="mt-3">
                                    <button type="submit" class="btn btn-theme mr-2 " (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-theme" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End block_modal  -->

<!-- Start active modal  -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to active <br>this user?</p>
                                <div class="mt-3">
                                    <button type="submit" class="btn btn-theme mr-2" (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-theme" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End active modal  -->
