<!------Start add contact us mobile number and email id----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
                                <div class="table-responsive mt-3">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">S. No</th>
                                                <th scope="col">E-Mail ID</th>
                                                <th scope="col">Contact No</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr>
                                                <td>1</td>
                                                <td>{{listing?.email}}</td>
                                                <td>{{(listing?.contactNo1 ? (listing?.contactNo1) : '') +
                                                      (listing?.contactNo2 ? (', ' + listing?.contactNo2) : '') +
                                                      (listing?.contactNo3 ? (', ' + listing?.contactNo3) : '') }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                            <tr>
                                                <td colspan="13" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br>
                                <br>
                                <div class="head_flt_select">
                                    <div class="head_flt_select input-label text-center">
                                        <button type="submit" class="btn btn-theme mr-3"
                                            (click)="setEditContactDetails()" *ngIf="IsSave || listing?.length==0"
                                            [disabled]="contactUsForm.invalid">Save</button>
                                        <button type="submit" class="btn btn-theme" (click)="editModal()"
                                            [disabled]="listing?.length" *ngIf="IsEdit">Edit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------End add contact us mobile number and email id----->

<!-- start modal of add contact us details  -->
<div class="modal fade global-modal reset-modal" id="contactUs">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Contact No And E-mail ID</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <form [formGroup]="contactUsForm">
                                    <div class="row  d-flex justify-content-end w_100 mt-3">

                                        <div class="col-lg-12">
                                            <input class="selectBox form-control" type="email" placeholder="E-Mail ID"
                                                formControlName="emailId">
                                            <div
                                                *ngIf="contactUsForm.get('emailId').hasError('required') && contactUsForm.get('emailId').touched">
                                                <span style="color:red">*Please enter email id.</span></div>
                                            <div *ngIf="contactUsForm.controls['emailId'].hasError('maxlength')"><span
                                                    style="color:red">*Maxlength should be 60 characters</span></div>
                                            <div *ngIf="contactUsForm.controls['emailId'].hasError('pattern')"><span
                                                    style="color:red">*Please enter valid email id.</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <input class="selectBox form-control" type="text" placeholder="Contact No"
                                                formControlName="contactNo1">
                                            <div
                                                *ngIf="contactUsForm.get('contactNo1').hasError('required') && contactUsForm.get('contactNo1').touched">
                                                <span style="color:red">*Please enter contact number.</span></div>
                                            <div *ngIf="contactUsForm.controls['contactNo1'].hasError('minlength')">
                                                <span style="color:red">*Minlength should be 10 digits.</span></div>
                                            <div *ngIf="contactUsForm.controls['contactNo1'].hasError('pattern')"><span
                                                    style="color:red">*Please enter valid contact number.</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <input class="selectBox form-control" type="text" placeholder="Contact No"
                                                formControlName="contactNo2">
                                            <div
                                                *ngIf="contactUsForm.get('contactNo2').hasError('required') && contactUsForm.get('contactNo2').touched">
                                                <span style="color:red">*Please enter contact number.</span></div>
                                            <div *ngIf="contactUsForm.controls['contactNo2'].hasError('minlength')">
                                                <span style="color:red">*Minlength should be 10 digits.</span></div>
                                            <div *ngIf="contactUsForm.controls['contactNo2'].hasError('pattern')"><span
                                                    style="color:red">*Please enter valid contact number.</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <input class="selectBox form-control" type="text" placeholder="Contact No"
                                                formControlName="contactNo3">
                                            <div
                                                *ngIf="contactUsForm.get('contactNo3').hasError('required') && contactUsForm.get('contactNo3').touched">
                                                <span style="color:red">*Please enter contact number.</span></div>
                                            <div *ngIf="contactUsForm.controls['contactNo3'].hasError('minlength')">
                                                <span style="color:red">*Minlength should be 10 digits.</span></div>
                                            <div *ngIf="contactUsForm.controls['contactNo3'].hasError('pattern')"><span
                                                    style="color:red">*Please enter valid contact number.</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div>
                                    <button type="button" class="btn btn-theme mr-3"
                                        data-dismiss="modal">Cancel</button>
                                    <button type="submit" class="btn btn-theme" (click)="UpdateContact()"
                                        [disabled]="contactUsForm.invalid">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End modal of add contact us details  -->
