<!--------Start view bid by fleet owner ------->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Supplier :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{bidData?.supplierName || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Mobile" class="col-sm-2 col-form-label">Site Name :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{bidData?.location || 'N/A'}}</label>
                            </div>
                            <label for="email" class="col-sm-2 col-form-label">Material :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{bidData?.material || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Mobile" class="col-sm-2 col-form-label">Weight :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{bidData?.weight || 'N/A'}}</label>
                            </div>
                            <label for="email" class="col-sm-2 col-form-label">Bid Date :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{bidData?.createTime | date:'medium' || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Amount :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{bidData?.bidAmount || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">PO Number :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{bidData?.poNumber || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Mobile" class="col-sm-2 col-form-label">Pick up Site :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{bidData?.location || 'N/A'}}</label>
                            </div>
                            <label for="email" class="col-sm-2 col-form-label">Drop Site :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{bidData?.dropLocation || 'N/A'}}</label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label" >
                            <button type="submit" class=" btn btn-theme" (click)="close()">Close</button>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!--------End view bid by fleet owner ------->
