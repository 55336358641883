<!----- Start view supplier billing ----->
<section>
  <div class="container1440 mt-5">
    <main class="middle-content">
      <div class="content-section">
        <div class="order-view mt30 mrgn-0-auto">
          <div>
            <form>
              <div class="form-group row">
                <label for="Sname" class="col-sm-2 col-form-label">Site Name</label>
                <div class="col-sm-4">
                  <label id="Sname" class="outup-label">{{bookingItems?.location|| 'NA'}}</label>
                </div>
                <label for="material" class="col-sm-2 col-form-label">Material</label>
                <div class="col-sm-4">
                  <label id="material" class="outup-label">{{bookingItems?.material|| 'NA'}}</label>
                </div>
              </div>
              <div class="form-group row">
                <label for="Mobile" class="col-sm-2 col-form-label">Weight</label>
                <div class="col-sm-4">
                  <label id="Mobile" class="outup-label">{{bookingItems?.weight|| 'NA'}}</label>
                </div>
                <label for="email" class="col-sm-2 col-form-label">Booking Date</label>
                <div class="col-sm-4">
                  <label id="email" class="outup-label">{{bookingItems?.createTime | date}}</label>
                </div>
              </div>

              <div class="form-group row">
                <label for="amount" class="col-sm-2 col-form-label">Amount</label>
                <div class="col-sm-4">
                  <label id="amount" class="outup-label">{{bookingItems?.bidAmount || 'NA'}}</label>
                </div>
                <label for="comanyAddress" class="col-sm-2 col-form-label">Invoice date</label>
                <div class="col-sm-4">
                  <label id="companyAddress" class="outup-label">{{bookingItems?.invoiceDateForSupplier |date }}</label>
                </div>
              </div>

              <div class="form-group row">
                <label for="city" class="col-sm-2 col-form-label">PO Number</label>
                <div class="col-sm-4">
                  <label id="city" class="outup-label">{{bookingItems?.poNumber || 'NA'}}</label>
                </div>
                <label for="state" class="col-sm-2 col-form-label">Invoice Number</label>
                <div class="col-sm-4">
                  <label id="state" class="outup-label">{{bookingItems?.invoiceNoForSupplier || 'NA'}}</label>

                </div>
              </div>
              <div class="form-group row">
                <label for="vehicle" class="col-sm-2 col-form-label">Vehicle Number</label>
                <div class="col-sm-4">
                  <label id="vehicle" class="outup-label">{{bookingItems?.truckNumber || 'NA'}}</label>
                </div>
                <label for="pan" class="col-sm-2 col-form-label">Vehicle Type</label>
                <div class="col-sm-4">
                  <label id="pan" class="outup-label">{{bookingItems?.truckType || 'NA'}}</label>
                </div>
              </div>
              <div class="form-group row">
                <label for="Dname" class="col-sm-2 col-form-label">Driver Name</label>
                <div class="col-sm-4">
                  <label id="Dname" class="outup-label">{{bookingItems?.driverName || 'NA'}}</label>
                </div>
                <label for="mob" class="col-sm-2 col-form-label">Driver Mobile No</label>
                <div class="col-sm-4">
                  <label id="mob" class="outup-label">{{bookingItems?.driverMobileNo || 'NA'}}</label>
                </div>
              </div>
              <div class="form-group row">
                <label for="load" class="col-sm-2 col-form-label">Loaded Weight</label>
                <div class="col-sm-4">
                  <label id="load" class="outup-label">{{bookingItems?.loadedWeight || 'NA'}}</label>
                </div>
                <label for="unlod" class="col-sm-2 col-form-label">Unloaded Weight</label>
                <div class="col-sm-4">
                  <label id="unlod" class="outup-label">{{bookingItems?.unLoadedWeight || 'NA'}}</label>
                </div>
              </div>
              <div class="form-group row">
                <label for="Rname" class="col-sm-2 col-form-label">Receiver Name</label>
                <div class="col-sm-4">
                  <label id="Rname" class="outup-label">{{bookingItems?.receiverName || 'NA'}}</label>
                </div>
                <label for="comnts" class="col-sm-2 col-form-label">Comments</label>
                <div class="col-sm-4">
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                    readonly>{{bookingItems?.comments||"N/A"}}</textarea>
                </div>
              </div>
              <div class="form-group row">
                <label for="pick" class="col-sm-2 col-form-label">PickUp Site</label>
                <div class="col-sm-4">
                  <label id="pick" class="outup-label">{{bookingItems?.location || 'NA'}}</label>
                </div>
                <label for="drop" class="col-sm-2 col-form-label">Drop Site</label>
                <div class="col-sm-4">
                  <label id="drop" class="outup-label">{{bookingItems?.dropLocation || 'NA'}}</label>
                </div>
              </div>
              <div class="form-group row">
                <label for="Fname" class="col-sm-2 col-form-label">Route Map :</label>
                <div class="col-sm-4">
                  <img [src]="MapUrl ? MapUrl:'assets/img/dummy.png'" alt="" class="view-img">
                </div>
                <label for="Lname" class="col-sm-2 col-form-label">View Weight Slip :</label>
                <div class="col-sm-4">
                  <img [src]="weightSlipUrl?weightSlipUrl:'assets/img/dummy.png'" alt="" class="view-img">
                </div>
              </div>
              <div class="form-group row">
                <label for="Mobile" class="col-sm-2 col-form-label">View Selfie :</label>
                <div class="col-sm-4">
                  <img [src]="selfiUrl ? selfiUrl:'assets/img/dummy.png'" alt="" class="view-img">
                </div>
                <label for="email" class="col-sm-2 col-form-label">View Invoice :</label>
                <div class="col-sm-4">
                  <img [src]="InvoiceUrl ? InvoiceUrl:'assets/img/dummy.png'" alt="" class="view-img">
                </div>
              </div>
            </form>
          </div>
          <br>
          <div class="head_flt_select">
            <div class="head_flt_select input-label">
              <button routerLink='/list-of-supplier-billing' type="submit" class="btn  btn-theme">Close</button>
            </div>
          </div>

        </div>
      </div>
    </main>
  </div>
</section>
<app-footer></app-footer>
<!----- End view supplier billing ----->
