<!------Start section of dashboard------>
<section class="dashboard">
    <div class="container1440">
        <main class="middle-content" >
            <div class="content-section mb-0">
                <div class="row">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="dash-list text-center">
                                    <div class="dash-list-inner dash-gray">
                                        <div class="w-100" (click)="navigateTo('list-of-companies')">
                                            <div class="dash_icn">
                                                <i class="fa fa-building-o fa-3x" aria-hidden="true"></i>
                                            </div>
                                            <h2>Total Number of <br> Companies</h2>
                                            <h4>{{dashboardCountData?.companyCount || 0}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row dash-border">
                                    <div class="col-md-12">
                                        <div class="dash-select text-center">
                                            <div class="dash-select-inner">
                                                <div class="w-100" >
                                                    <select class="form-control" name="" id=""
                                                        (change)="getCompanyCount($event)">
                                                        <option value="">Select Company</option>
                                                       
                                                        <option *ngFor="let item of companyName" [value]="item?.name">
                                                            {{item?.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="dash-list text-center">
                                            <div class="dash-list-inner dash-gray">
                                                <div class="w-100" (click)="navigateTo('list-of-company-billing')">
                                                    <div class="dash_icn">
                                                        <i class="fa fa-file-text fa-3x" aria-hidden="true"></i>
                                                    </div>
                                                    <h2>Total number of <br> pending invoices</h2>
                                                    <h4>{{companyCount?.invoiceCount || 0}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="dash-list text-center">
                                            <div class="dash-list-inner dash-gray">
                                                <div class="w-100" (click)="navigateTo('list-of-company-booking')">
                                                    <div class="dash_icn">
                                                        <i class="fa fa-calendar-check-o fa-3x" aria-hidden="true"></i>
                                                    </div>
                                                    <h2>Today's Scheduled <br> trips</h2>
                                                    <h4>{{companyCount?.tudaysScheduleTripCompany || 0}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="dash-list text-center">
                                    <div class="dash-list-inner dash-gray">
                                        <div class="w-100" (click)="navigateTo('list-of-supplier')">
                                            <div class="dash_icn">
                                            
                                                <img src="assets/img/Supplier.png">
                                            </div>
                                            <h2>Total Number of<br> Suppliers</h2>
                                            <h4>{{dashboardCountData?.supplierCount || 0}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row dash-border">
                                    <div class="col-md-12">
                                        <div class="dash-select text-center">
                                            <div class="dash-select-inner">
                                                <div class=" w-100">
                                                    <select class="form-control" name="" id=""
                                                        (change)="getSupplierCount($event)">
                                                        <option value="">Select Supplier</option>
                                                       
                                                        <option *ngFor="let item of supplierName" [value]="item.name">
                                                            {{item.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="dash-list text-center">
                                            <div class="dash-list-inner dash-gray">
                                                <div class="w-100" (click)="navigateTo('list-of-supplier-billing')">
                                                    <div class="dash_icn">
                                                        <i class="fa fa-line-chart fa-3x" aria-hidden="true"></i>
                                                    </div>
                                                    <h2>Total Revenue last<br> One Year</h2>
                                                    <h4>{{supplierCount?.totalRevenueLastOneYear || 0}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="dash-list text-center">
                                            <div class="dash-list-inner dash-gray">
                                                <div class="w-100" (click)="navigateTo('list-of-supplier-booking')">
                                                    <div class="dash_icn">
                                                        <i class="fa fa-calendar-check-o fa-3x" aria-hidden="true"></i>
                                                    </div>
                                                    <h2>Today's Scheduled<br> trips</h2>
                                                    <h4>{{supplierCount?.tudaysScheduleTrip || 0}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="dash-list text-center">
                                    <div class="dash-list-inner dash-gray">
                                        <div class="w-100" (click)="navigateTo('list-of-fleet-owner')">
                                            <div class="dash_icn">
                                                <i class="fa fa-user-secret  fa-3x" aria-hidden="true"></i>
                                            </div>
                                            <h2>Total Number of<br> Fleet Owners</h2>
                                            <h4>{{dashboardCountData?.fleetCount || 0}}</h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <div class="dash-list text-center">
                                    <div class="dash-list-inner dash-gray">
                                        <div class="w-100" (click)="navigateTo('list-of-driver')">
                                            <div class="dash_icn">
                                              
                                                <img src="assets/img/driver.png">
                                            </div>
                                            <h2>Total Number of <br> Active Drivers</h2>
                                            <h4>{{dashboardCountData?.driverCount || 0}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="dash-list text-center">
                                    <div class="dash-list-inner dash-gray">
                                        <div class="w-100" (click)="navigateTo('list-of-truck')">
                                            <div class="dash_icn">
                                                <i class="fa fa-truck fa-3x" aria-hidden="true"></i>
                                            </div>
                                            <h2>Total Number of <br> Active Trucks</h2>
                                            <h4>{{dashboardCountData?.truckCount || 0}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------End section of dashboard------>


