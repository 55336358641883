<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#e0a354" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner> -->
<ngx-spinner type="none" bdColor="rgba(255,255,255,0.5)">
    <svg
  width="300px"
  height="200px"
  viewBox="0 0 187.3 93.7"
  preserveAspectRatio="xMidYMid meet"
  

>
  <path
    stroke="#ff0610"
    id="outline"
    fill="none"
    stroke-width="4"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-miterlimit="10"
    d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
  />
  <path
    id="outline-bg"
    opacity="0.09"
    fill="none"
    stroke="#ff0610"
    stroke-width="4"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-miterlimit="10"
    d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
  />
</svg>
</ngx-spinner>
<app-sidebar></app-sidebar>
<router-outlet></router-outlet>

<!-- <footer style="background: black;" *ngIf="router.url != 'dashboard'">
  <div class="container-fluid" >
      <p class="copyright">Copyright @ 2020 All Rights Reserved  <strong class="theme-text-color"></strong>
          </p>
  </div>
</footer> -->