import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
declare var $: any

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  IsUpdate: boolean = false;
  IsSave: boolean = false
  IsEdit: boolean = true
  contactUsForm: FormGroup;
  listing: any = [];

  constructor(public service: MainService, private fb: FormBuilder) { }

  ngOnInit() {
    this.getContactDetails()
    this.contactUsForm = this.fb.group({
      'emailId': ["", Validators.compose([Validators.required, Validators.maxLength(60), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])],
      'contactNo1': ["", Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(15), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])],
      'contactNo2': ["", Validators.compose([Validators.minLength(10), Validators.maxLength(15), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])],
      'contactNo3': ["", Validators.compose([Validators.minLength(10), Validators.maxLength(15), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])],
    })
  }

  // set static content
  setEditContactDetails() {
    let url = `account/admin/set-edit-contact-Details`
    let obj = {
      "contactNo1": this.contactUsForm.value.contactNo1,
      "contactNo2": this.contactUsForm.value.contactNo2,
      "contactNo3": this.contactUsForm.value.contactNo3,
      "email": this.contactUsForm.value.emailId,
    }
    this.service.showSpinner()
    this.service.post(url, obj).subscribe((res: any) => {
      console.log('This is contact Us', res)
      this, this.service.hideSpinner()
      this.contactUsForm.reset()
      if (res.status == 200) {
        this.service.toasterSucc(res.message)
        this.getContactDetails()
      }
      else {
        this.service.toasterErr(res.message)
        this.getContactDetails()
      }
    }, err => {
      this.service.toasterErr('Something went wrong.')
      this.getContactDetails()
    })
  }

  // EditContactUsDetails() {
  //   this.IsUpdate = true;
  //   this.IsSave = false
  //   this.IsEdit = false
  //   this.contactUsForm.patchValue({
  //     emailId: this.listing.email,
  //     contactNo: this.listing.contactNo
  //   })
  // }

  getContactDetails() {
    let url = `account/admin/get-contact-Details`
    this.service.showSpinner()
    this.service.get(url).subscribe((res: any) => {
      console.log('Conatc Get', res.data);

      this, this.service.hideSpinner()
      if (res.status == 200) {
        this.listing = res.data[0];
        console.log('contact-ID', this.listing.contactDetailId)
        this.service.toasterSucc(res.message)
      }
      else {
        this.service.toasterErr(res.message)
      }
    }, err => {
      this.service.toasterErr('Something went wrong.')
    })
  }

  UpdateContact() {
    let url = `account/admin/set-edit-contact-Details`
    let obj = {
      "contactDetailId": this.listing.contactDetailId,
      "email": this.contactUsForm.value.emailId,
      "contactNo1": this.contactUsForm.value.contactNo1,
      "contactNo2": this.contactUsForm.value.contactNo2,
      "contactNo3": this.contactUsForm.value.contactNo3,
    }
    this.service.showSpinner()
    this.service.post(url, obj).subscribe((res: any) => {
      console.log('This is contact Us', res)
      this, this.service.hideSpinner()
      this.contactUsForm.reset()
      $('#contactUs').modal('hide')
      if (res.status == 200) {
        this.IsUpdate = false
        this.IsEdit = true
        this.service.toasterSucc(res.message)
        this.getContactDetails()
      }
      else {
        this.service.toasterErr(res.message)
        this.getContactDetails()
      }
    }, err => {
      this.service.toasterErr('Something went wrong.')
      this.getContactDetails()
    })
  }

  // edit modal
  editModal() {
    this.contactUsForm.patchValue({
      emailId: this.listing.email,
      contactNo1: this.listing.contactNo1,
      contactNo2: this.listing.contactNo2,
      contactNo3: this.listing.contactNo3
    })
    $('#contactUs').modal('show')
  }
}
