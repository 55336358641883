<!--------Start company user management section in company management------>
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
                                <form [formGroup]="userForm">
 <!------- Start Search section and search by user type, company name, site, first name, last name and mobile -------->                                 
                                <div class="row">                                 
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label>User Type</label><br>
                                        <select id="city" class="form-control" formControlName="userType" (change)="getCompanyUserList()">
                                            <option value="COMPANY_ADMIN">Admin</option>
                                            <option value="COMPANY_SITE_ENGG">Site Engineer</option>
                                            <option value="COMPANY_MANAGER">Company Manager</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label>Company Name</label><br>
                                        <select id="CompanyName" class="selectBox form-control"  formControlName="companyName">
                                            <option value="">Company name</option>
                                            <option *ngFor="let company of companyNameArr" >{{company.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label>Site</label><br>
                                        <input type="text" id="Mobile" class="form-control selectBox"
                                        placeholder="Site" formControlName="siteAddress" >
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label>First Name</label><br>
                                        <input type="text" id="Mobile" placeholder="First name" class="selectBox form-control" formControlName="firstName">
                                    <span class="error"
                                        *ngIf="userForm.get('firstName').hasError('pattern') && userForm.get('firstName').dirty"
                                        class="error">
                                        <p> *Please enter valid first name.</p>
                                    </span>
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label>Last Name</label><br>
                                        <input type="text" id="Mobile"  placeholder="Last name" class="selectBox form-control" formControlName="lastName">
                                    <span class="error"
                                        *ngIf="userForm.get('lastName').hasError('pattern') && userForm.get('lastName').dirty"
                                        class="error">
                                        <p> *Please enter valid Last name.</p>
                                    </span>
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label>Mobile</label><br>
                                        <input type="text" id="Mobile"  placeholder="Mobile" class="selectBox form-control" formControlName="phoneNo" maxlength="13"
                                        >
                                    <span class="error"
                                        *ngIf="userForm.get('phoneNo').hasError('pattern') && userForm.get('phoneNo').dirty"
                                        class="error">
                                        <p> *Please enter valid mobile number.</p>
                                    </span>
                                    </div>
                                    <div class="col-lg-2 mt-3  p-0">
                                        <button type="button" class="search-btn" (click)="search()">Search</button>
                                    </div>
                                </div>
                                <div class="row  d-flex justify-content-end w_100 mt-3 mb-3">
                                    <i class='fas fa-file-csv mr-1' style='font-size:35px;color:green'
                                        (click)="ExportToCsv()"></i>
                                    <button type="button" class="search-btn" (click)="reset()">Reset</button>
                                    <button type="button" class="btn-add" (click)="addCompanyUser()">Add</button>
                                </div>
                                </form>
 <!------- End Search section and search by user type, company name, site, first name, last name and mobile -------->
<!------- Start list of company user ------->
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Company Name</th>
                                                <th scope="col">Mobile No</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Roll Type</th>
                                                <th scope="col">Status</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of listing| paginate: { itemsPerPage: itemsPerPage,currentPage: currentPage,totalItems: totalRecords };let i = index ">
                                                <td>{{item?.companyName}}</td>
                                                <td>{{item?.phoneNo}}</td>
                                                <td>{{item?.firstName}}</td>
                                                <td>{{item?.lastName}}</td>
                                                <td>{{item?.email}}</td>
                                                <td>{{item?.baseLocationAddress}}</td>
                                                <td>{{item?.role.role}}</td>
                                                <td>{{item?.userStatus}}</td>
                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <a tooltip="View"><i class="fa fa-eye" (click)="viewCompanyUser(item?.userId)"></i></a>
                                                    <a tooltip="Delete"><i class="fa fa-trash" (click)="deleteCompanyUser(item?.userId)"></i></a>
                                                    <a *ngIf="item?.userStatus == 'ACTIVE'" tooltip="Block" (click)="openModal('BLOCK',item?.userId)"><i class="fa fa-ban" style="color: green;"></i></a>
                                                    <a *ngIf="item?.userStatus == 'BLOCK'" tooltip="Active" (click)="openModal('ACTIVE',item?.userId)"><i class="fa fa-ban" style="color: red;"></i></a>
                                                    <a tooltip="Reset Password"><i class="fa fa-unlock-alt" (click)="resetPassword(item?.userId, item?.phoneNo, item?.email,item?.role.role)"></i></a>
                                                </td>
                                            </tr>
                                        
                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                        <tr>
                                            <td colspan="13" vertical-align="middle">
                                                <div class="no-record">
                                                    <div class="no-recordin">
                                                        <H5 style="margin-left: 44%;">No record found</H5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
<!------- End list of company user ------->
<!--------Start pagination section for list of company user------->
                                <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                *ngIf="listing?.length">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
<!--------End pagination section for list of company user------->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!--------End company user management section in company management------>

<!-- Start block modal -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Block Company User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to block this company user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " (click)="blockUser()"
                                        style="width: 25%;background-color: black;" >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End block modal -->

<!-- Start active modal -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Active Company User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to active this company user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2" (click)="blockUser()"
                                        >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End active modal -->
