<!------Start add truck ----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <form [formGroup]="addForm">
                            <div class="form-group row">
                                <label for="Fname" class="col-sm-2 col-form-label">Fleet Owner Name</label>
                                <div class="col-sm-4">
                                    <select name="" id="" class="form-control" formControlName="fleetOnwerNo">
                                        <option value="">Fleet Owner Name</option>
                                        <option *ngFor="let item of listing" [value]="item.userId">{{item.companyName}}
                                        </option>
                                    </select>
                                    <span class="error"
                                        *ngIf="addForm.get('fleetOnwerNo').hasError('required') && (addForm.get('fleetOnwerNo').dirty || addForm.get('fleetOnwerNo').touched )"
                                        class="error">
                                        <p>*Please select fleet owner name.</p>
                                    </span>
                                </div>
                                <label for="Lname" class="col-sm-2 col-form-label">Truck Type</label>
                                <div class="col-sm-4">
                                    <select name="" id="" class="form-control" formControlName="typeOfTruck">
                                        <option value="">Truck Type</option>
                                        <option *ngFor="let item of listingTruckType" [value]="item.name">
                                            {{item.name}}</option>
                                    </select>
                                    <span class="error"
                                        *ngIf="addForm.get('typeOfTruck').hasError('required') && (addForm.get('typeOfTruck').dirty || addForm.get('typeOfTruck').touched )"
                                        class="error">
                                        <p>*Please select truck type.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Mobile" class="col-sm-2 col-form-label">Truck Number</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="Truck Number"
                                        formControlName="registrationNo" maxlength="15">
                                    <span class="error"
                                        *ngIf="addForm.get('registrationNo').hasError('required') && (addForm.get('registrationNo').dirty || addForm.get('registrationNo').touched )"
                                        class="error">
                                        <p>*Please enter truck number.</p>
                                    </span>
                                </div>
                                <label for="email" class="col-sm-2 col-form-label">Description</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="Description"
                                        formControlName="description">
                                    <span class="error"
                                        *ngIf="addForm.get('description').hasError('required') && (addForm.get('description').dirty || addForm.get('description').touched )"
                                        class="error">
                                        <p>*Please enter description.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Mobile" class="col-sm-2 col-form-label">User Name</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="User Name"
                                        formControlName="userNameForLocation" maxlength="60">
                                    <span class="error"
                                        *ngIf="addForm.get('userNameForLocation').hasError('required') && (addForm.get('userNameForLocation').dirty || addForm.get('userNameForLocation').touched )"
                                        class="error">
                                        <p>*Please enter user name.</p>
                                    </span>
                                </div>
                                <label for="email" class="col-sm-2 col-form-label">Password</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="Password"
                                        formControlName="passwordForLocation" maxlength="32">
                                    <span class="error"
                                        *ngIf="addForm.get('passwordForLocation').hasError('required') && (addForm.get('passwordForLocation').dirty || addForm.get('passwordForLocation').touched )"
                                        class="error">
                                        <p>*Please enter password.</p>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="Fname" class="col-sm-2 col-form-label">Truck Company Name</label>
                                <div class="col-sm-4">
                                    <select name="" id="" class="form-control" formControlName="truckCompanyNameForLocation">
                                        <option value="">Truck Company Name</option>
                                        <option *ngFor="let item of companyNameLiveLocationArray" [value]="item">{{item}}
                                        </option>
                                    </select>
                                    <span class="error"
                                        *ngIf="addForm.get('truckCompanyNameForLocation').hasError('required') && (addForm.get('truckCompanyNameForLocation').dirty || addForm.get('truckCompanyNameForLocation').touched )"
                                        class="error">
                                        <p>*Please select truck company name.</p>
                                    </span>
                                </div>
                                <label for="email" class="col-sm-2 col-form-label">GPS Url</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="GPS Url"
                                        formControlName="urlForLocation">
                                    <span class="error"
                                        *ngIf="addForm.get('urlForLocation').hasError('required') && (addForm.get('urlForLocation').dirty || addForm.get('urlForLocation').touched )"
                                        class="error">
                                        <p>*Please enter gps url.</p>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="Mobile" class="col-sm-2 col-form-label">Access Token</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder="Access Token"
                                        formControlName="accessTokenForLocation" maxlength="60">
                                </div>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label">
                            <button type="submit" class="btn btn-theme" [disabled]="addForm.invalid"
                                (click)="submitForm()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------End add truck ----->
