<!------Start view company quote----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Supplier Name :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="col-form-label">{{listing.supplierName}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Material :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="col-form-label">{{listing.material}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Mobile" class="col-sm-2 col-form-label">Weight :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="col-form-label">{{listing.weight}}</label>
                            </div>
                            <label for="email" class="col-sm-2 col-form-label">Quote Date :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="col-form-label">{{listing.createTime | date: 'short'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Amount :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="col-form-label">Rs.{{listing.bidAmount}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Drop Site :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="col-form-label">{{listing.location}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">PO Number :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="col-form-label">{{listing.poNumber}}</label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label">
                            <button type="submit" class="btn-close" (click)="close()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------End view company quote----->
