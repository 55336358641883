<!------Start add terms and conditions---->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="padding-bottom: 1px;min-width:654px">
                    <div class="main-block-innner mb40 mt40">
                        <div class="add-store-block input-style" style="margin-top: -7%;">
                            <div class="form-group row ">
                                <div class="col-md-12">
                                    <ck-editor name="editor1" aria-placeholder="Description" debounce="500"
                                        [(ngModel)]="data.pageData" skin="moono-lisa" language="en" [fullPage]="true">
                                    </ck-editor>
                                </div>
                            </div>
                            <div class="text-center mt40">
                                <a href="javascript:void(0)" class="btn btn-theme"
                                    (click)="saveTermsAndConditions()">Save</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------End add terms and conditions---->
