<!-----Start view sites----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <form [formGroup]="viewSiteForm">
                            <div class="form-group row">
                                <label for="Fname" class="col-sm-2 col-form-label">Company Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Fname" class="form-control" placeholder="Company name"
                                        formControlName="companyName" disabled>
                                </div>
                                <label for="Lname" class="col-sm-2 col-form-label">Address</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Lname" class="form-control" placeholder="Address"
                                        formControlName="address" disabled>
                                </div>
                            </div>
                            <div class="form-group row">                            
                                <label for="city" class="col-sm-2 col-form-label">City</label>
                                <div class="col-sm-4">
                                <input type="text" id="city" class="form-control"
                                placeholder="City" formControlName="city" disabled>
                            </div>
                                <label for="state" class="col-sm-2 col-form-label">State</label>
                                <div class="col-sm-4">
                                <input type="text" id="state" class="form-control"
                                placeholder="State" formControlName="state" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="gst" class="col-sm-2 col-form-label">GISTN No</label>
                                <div class="col-sm-4 ">
                                    <input type="text" id="gst" class="form-control" placeholder="Enter GISTN no"
                                        style="border: 1px solid;" formControlName="gstNo" maxlength="16" disabled>
                                </div>
                                <label for="gst" class="col-sm-2 col-form-label">Email</label>
                                <div class="col-sm-4 ">
                                    <input type="text" id="gst" class="form-control" placeholder="Email"
                                        style="border: 1px solid;" formControlName="email"  disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6"></div>
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4">
                                    <img [src]="gstimageUrl" alt=""> 
                                </div>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label" >
                            <button type="submit" class="btn btn-theme mr-3" (click)="back()">Back</button>
                            <button type="submit" class="btn btn-theme" (click)="edit()">Edit</button>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!-----End view sites----->
