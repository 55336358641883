import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $:any
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bit-coin-wallet-admin-panel';

  constructor(public router: Router, private service :MainService) { 
    var self = this;
    $(document).bind("mousedown", function (e) {
      if (e.button == 0) {
        self.checkSession();
      }
    });
  }

  ngOnInit() {
    this.checkSession();
  }

  /** Function to get diffrence between timestamp */
  diff_minutes(dt2, dt1) {
    var diff = (dt2 - dt1) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }
  /** Function for check session */
  checkSession() {
    if (localStorage.getItem('Auth') != null && localStorage.getItem('session') != null) {
      let minDiff = this.diff_minutes(parseInt(localStorage.getItem('session')), new Date().getTime());
      localStorage.setItem('session', new Date().getTime().toString());
      if (minDiff >= 36000000) {
        this.logout();
      }
    } else {
      localStorage.setItem('session', new Date().getTime().toString());
    }
  }
  logout() {
    this.service.toasterErr('Session Expired!');
    localStorage.removeItem('data');
    localStorage.removeItem('Auth');
    localStorage.removeItem('permission');
    localStorage.removeItem('usertype');
    $('#signout_modal').modal('hide');
   
    this.router.navigate(['/login']);
  }
}
