<!----Start edit fleet owner ---->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">
                  <div>
                        <form [formGroup]="editForm">
                            <div class="form-group row">
                                <label for="Fname" class="col-sm-2 col-form-label">First Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Fname" class="form-control" placeholder="First name"
                                        formControlName="firstName" maxlength="60">
                                    <span class="error"
                                        *ngIf="editForm.get('firstName').hasError('required') && (editForm.get('firstName').dirty || editForm.get('firstName').touched )"
                                        class="error">
                                        <p>*Please enter first name.</p>
                                    </span>
                                    <span class="error"
                                        *ngIf="editForm.get('firstName').hasError('pattern') && editForm.get('firstName').dirty"
                                        class="error">
                                        <p> *Please enter valid first name.</p>
                                    </span>
                                </div>
                                <label for="Lname" class="col-sm-2 col-form-label">Last Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Lname" class="form-control" placeholder="Last name"
                                        formControlName="lastName" maxlength="60">
                                    <span class="error"
                                        *ngIf="editForm.get('lastName').hasError('required') && (editForm.get('lastName').dirty || editForm.get('lastName').touched )"
                                        class="error">
                                        <p>*Please enter last name.</p>
                                    </span>
                                    <span class="error"
                                        *ngIf="editForm.get('lastName').hasError('pattern') && editForm.get('lastName').dirty"
                                        class="error">
                                        <p> *Please enter valid Last name.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Mobile" class="col-sm-2 col-form-label">Mobile No</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Mobile" class="form-control"
                                        placeholder="Enter Mobile Number" formControlName="phoneNo" maxlength="20">
                                    <span class="error"
                                        *ngIf="editForm.get('phoneNo').hasError('required') && (editForm.get('phoneNo').dirty || editForm.get('phoneNo').touched )"
                                        class="error">
                                        <p> *Please enter mobile number.</p>
                                    </span>
                                    <span class="error"
                                        *ngIf="editForm.get('phoneNo').hasError('pattern') && editForm.get('phoneNo').dirty"
                                        class="error">
                                        <p> *Please enter valid mobile number.</p>
                                    </span>
                                </div>
                                <label for="email" class="col-sm-2 col-form-label">Email ID</label>
                                <div class="col-sm-4">
                                    <input type="text" id="email" class="form-control" placeholder="Enter mail Id"
                                        formControlName="email" maxlength="60">
                                    <span class="error">
                                        <p *ngIf="editForm.get('email').hasError('required') && (editForm.get('email').dirty || editForm.get('email').touched )"
                                            class="error" padding>*Please enter email id.</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="editForm.get('email').hasError('pattern') && editForm.get('email').dirty"
                                            class="error" padding>*Please enter valid email.</p>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="CompanyName" class="col-sm-2 col-form-label">Company Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="CompanyName" class="form-control" placeholder="Company name"
                                        formControlName="companyName" maxlength="60">
                                    <span class="error">
                                        <p *ngIf="editForm.get('companyName').hasError('required') && (editForm.get('companyName').dirty || editForm.get('companyName').touched )"
                                            class="error" padding>*Please enter company name.</p>
                                    </span>
                                </div>
                                <label for="comanyAddress" class="col-sm-2 col-form-label">Company Address</label>
                                <div class="col-sm-4">
                                    <input type="text" id="comanyAddress" class="form-control"
                                        placeholder="Company Address" formControlName="baseLocationAddress"
                                        maxlength="60">
                                    <span class="error">
                                        <p *ngIf="editForm.get('baseLocationAddress').hasError('required') && (editForm.get('baseLocationAddress').dirty || editForm.get('baseLocationAddress').touched )"
                                            class="error" padding>*Please enter company address.</p>
                                    </span>
                                </div>
                            </div>


                            <div class="form-group row">
                                <label for="state" class="col-sm-2 col-form-label">State</label>
                                <div class="col-sm-4">
                                    <select id="city" class="form-control" formControlName="state"
                                        (change)="searchCity($event)">
                                        <option value="">State</option>
                                        <option *ngFor="let state of stateArr" [value]="state?.name">{{state?.name}}
                                        </option>
                                    </select>
                                </div>
                                <label for="city" class="col-sm-2 col-form-label">City</label>
                                <div class="col-sm-4">
                                    <select id="city" class="form-control" formControlName="city">
                                        <option value="">City</option>
                                        <option *ngFor="let city of cityArr" [value]="city?.name">{{city?.name}}
                                        </option>
                                    </select>
                                </div>

                            </div>
                            <div class="form-group row">
                                <label for="aadhar" class="col-sm-2 col-form-label">Aadhar No</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="aadhar" class="form-control" placeholder="Enter aadhar no"
                                        formControlName="aadharCardNo" maxlength="12">
                                    <div class="input-group-append">
                                        <button class="btn" type="button"
                                            style="background-color: green;     height: 38px;     z-index: 0;">Validate</button>
                                    </div>
                                    <img *ngIf="aadharCardUrl" [src]="aadharCardUrl" alt="" class="view-img">

                                </div>
                                <label for="pan" class="col-sm-2 col-form-label">Pan Card</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="pan" class="form-control" placeholder="Enter Pan no"
                                        formControlName="panCardNo" maxlength="20">

                                    <div class="input-group-append">
                                        <button class="btn" type="button"
                                            style="background-color: green;     height: 38px;     z-index: 0;">Validate</button>
                                    </div>
                                    <img *ngIf="panCardUrl" [src]="panCardUrl" alt="" class="view-img">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4">
                                    <label for="AadharCard" class="btn btn-success btn-block btn-outlined">Upload Aadhar
                                        Card</label>

                                    <input type="file" id="AadharCard" accept="image/*" style="display: none"
                                        class="form-control" (change)="uploadAadhaar($event)">


                                </div>
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4">
                                    <label for="PanCard" class="btn btn-success btn-block btn-outlined">Upload Pan
                                        Card</label>
                                    <input type="file" id="PanCard" accept="image/*" style="display: none"
                                        class="form-control" (change)="uplaodPan($event)">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="gst" class="col-sm-2 col-form-label">GISTN No</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="gst" class="form-control" placeholder="Enter GISTN no"
                                        formControlName="gstinNo" maxlength="20">
                                    <div class="input-group-append">
                                        <button class="btn" type="button"
                                            style="background-color: green;     height: 38px;     z-index: 0;">Validate</button>
                                    </div>
                                    <img *ngIf="gstinUrl" [src]="gstinUrl" alt="" class="view-img">

                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4">
                                    <label for="gstfile" class="btn btn-success btn-block btn-outlined">GISTN
                                        Copy</label>
                                    <input type="file" id="gstfile" accept="image/*" style="display: none"
                                        class="form-control" (change)="uplaodGst($event)">
                                </div>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label">
                            <button type="submit" class="btn btn-theme mr-3" (click)="cancel()">Cancel</button>
                            <button type="submit" class="btn btn-theme" (click)="editFleetOwner()">Update</button>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!----End edit fleet owner ---->
