<!------Start truck section in fleet management----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
<!------- Start Search section and search by fleet owner company, truck number, truck type, mobile-fleet owner-------->
                                <div class="row">
                                    <div class="col-lg-3  mt-4">
                                        <label>Fleet Owner Company</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="fleetOnwerCompanyName">
                                            <option value="">Select Fleet Owner</option>
                                            <option *ngFor="let item of fleetOnwerCompanyNameArray"
                                                [value]="item?.companyName">{{item?.companyName}}</option>
                                        </select>
                                    </div>

                                    <div class="col-lg-3  mt-4">
                                        <label>Truck No</label><br>
                                        <input type="text" class="form-control input-field" placeholder="Truck No"
                                            [(ngModel)]="registrationNo" maxlength="20">
                                    </div>
                                    <div class="col-lg-3  mt-4">
                                        <label>Truck Type</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="typeOfTruck">
                                            <option value="">Truck Type</option>
                                            <option *ngFor="let item of typeOfTruckArray" [value]="item?.name">
                                                {{item?.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 mt-4">
                                        <label>Mobile - Fleet Owner</label><br>
                                        <input type="text" class="form-control input-field"
                                            placeholder="Fleet Owner Mobile" [(ngModel)]="fleetOnwerNo"
                                            (keypress)="service.numberOnly($event)" maxlength="20">
                                    </div>
                                </div>
                                <div class="row  d-flex justify-content-end w_100 mt-3 mb-3">
                                    <i class='fas fa-file-csv mr-1' style='font-size:35px;color:green; cursor: pointer;'
                                        (click)="exportToCsv()" aria-hidden="true"></i>
                                    <button type="button" class="search-btn" (click)="search()">Search</button>
                                    <button type="button" class="search-btn" (click)="reset()">Reset</button>
                                    <button type="button" class="btn-add" (click)="addTruck()">Add</button>
                                </div>
<!------- End Search section and search by fleet owner company, truck number, truck type, mobile-fleet owner-------->
<!------- Start list of truck ------->
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Fleet Owner Company</th>
                                                <th scope="col">Truck Number</th>
                                                <th scope="col">Truck Type</th>
                                                <th scope="col">Mobile Fleet Owner</th>
                                                <th scope="col">Date Of Creation</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let item of listing| paginate: { itemsPerPage: itemsPerPage,currentPage: currentPage, totalItems: totalItems };let i = index ">
                                                <td>{{item?.fleetOnwerCompanyName}}</td>
                                                <td>{{item?.registrationNo}}</td>
                                                <td>{{item?.typeOfTruck}}</td>
                                                <td>{{item?.fleetOnwerNo}}</td>
                                                <td>{{item?.createTime | date:'medium'}}</td>
                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <a tooltip="View" (click)="viewTruck(item?.truckId)"><i class="fa fa-eye"
                                                            aria-hidden="true"></i></a>
                                                    <a tooltip="Delete" (click)="deleteTruck(item?.truckId)"><i class="fa fa-trash"
                                                            aria-hidden="true"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                            <tr>
                                                <td colspan="6" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
<!------- End list of truck ------->
<!--------Start pagination section for list of truck------->
                                <div *ngIf="totalItems > 10" class="custom-pagination mt20"
                                    style="display: flex; justify-content:flex-end">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
<!--------End pagination section for list of truck------->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------End truck section in fleet management----->
