<!-----Start section of change password---->
<section>
  <div class="container1440 mt-5">
    <main class="middle-content" style="min-height: 520px;">
      <div class="content-section">
          <div class="order-view mt30 mrgn-0-auto change-password-div">
            <i class="fa fa-times-circle-o" aria-hidden="true" (click)="navigateTo()"></i>
          <div>
            <div class="add-store-block input-style mb20 mt20 ">
              <form [formGroup]="form" #f="ngForm">
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-5 textalignright " style="font-size: 16px;font-weight: 600;">Enter Old Password
                    <span>:</span></label>
                  <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Enter Old Password"
                        formControlName="oldPassword" maxlength="30">
                      <div
                        *ngIf="form.get('oldPassword').invalid && (form.get('oldPassword').touched || form.get('oldPassword').dirty)"
                        class="text-danger">
                        <span *ngIf="form.get('oldPassword').hasError('required') ">*Please enter old password.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-5 textalignright " style="font-size: 16px;font-weight: 600;">Enter New Password
                    <span>:</span></label>
                  <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                      <input type="password" class="form-control" placeholder="Enter New Password"
                        formControlName="newPassword" maxlength="30">
                      <div
                        *ngIf="form.get('newPassword').invalid && (form.get('newPassword').touched || form.get('newPassword').dirty)"
                        class="text-danger">
                        <span *ngIf="form.get('newPassword').hasError('required')">
                          *Please enter new password.</span>
                        <span *ngIf="form.get('newPassword').hasError('pattern')">
                          *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1
                          number and 1 special character.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-5 textalignright " style="font-size: 16px;font-weight: 600;">Reenter New Password
                    <span>:</span></label>
                  <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                      <input type="password" class="form-control" placeholder="Reenter New Password"
                        formControlName="confirmPassword" maxlength="30">
                      <div *ngIf="form.get('confirmPassword').dirty" class="text-danger">
                        <span *ngIf="form.get('confirmPassword').value != form.get('newPassword').value">*Password
                          mismatched.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="text-center  mb40">
              <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="form.invalid" (click)="changePassword()"
                style="justify-content: space-between" style="font-size: 15px;">Submit</button>&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</section>
<app-footer></app-footer>
<!-----End section of change password---->
