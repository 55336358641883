<!-------Start login ------->
<body class="afterlogin" style="min-height: 717px">
    <div class="login-wrapper">
        <div class="container-common">
            <div class="row justify-content-center ">
                <div class="col-md-6">
                    <form class="login_box_outer" [formGroup]="loginForm">
                        <div class="login-box">
                            <div class="login-right-block">
                                <div class="login-heading">
                                    <h4 style="color:black">LOGIN</h4>
                                </div>
                                <div class="login-box-body">
                                    <div class="form-group">
                                        <label for="">Enter User ID</label>
                                        <input type="text" class="form-control" placeholder="Please enter User ID"
                                            formControlName="phoneNo" (keypress)="service.preventSpace($event)" />
                                        <div
                                            *ngIf="loginForm.get('phoneNo').hasError('required') && loginForm.get('phoneNo').touched">
                                            <span class="error">*User ID is required.</span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="">Enter your Password</label>

                                        <input type="password" class="form-control " placeholder="Please enter Password"
                                            formControlName="password" (keypress)="service.preventSpace($event)" />
                                        <div
                                            *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched">
                                            <span class="error">*Password is required.</span>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-6">
                                            <div class="remember-text ">
                                                <label class="checkbox-design" style="color:black">
                                                    <input type="checkbox"
                                                        formControlName='rememberMe' /><span></span>Remember me
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="forgot-links">
                                                <a href="javascript:;" (click)="forgotPassword()"
                                                    routerLinkActive="router-link-active" style="color:black">Forgot
                                                    Password?</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group text-center mt40">
                                        <button type="button" style="background: black;width: 53%;"
                                            class="btn btn-login btn-large  width100 font-100"
                                            [disabled]="loginForm.invalid" (click)="login()">LOGIN</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <footer class="footer">
            <p class="copyright">Copyright @ 2020 All Rights Reserved <strong class="theme-text-color"></strong></p>
    </footer>
<!-------End login ------->
