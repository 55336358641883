<!-------Start forgot password ------>
<body class="afterlogin bg-image" style="min-height: 600px;">
    <div class="login-wrapper ">
        <div class="container-common">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form class="login_box_outer" [formGroup]="forgotPasswordForm">
                        <div class="login-box">
                            <div class="login-right-block">
                                <div class="login-heading">
                                    <h4 style="color: black;">FORGOT PASSWORD</h4>
                                </div>
                                <div class="login-box-body">
                                    <div class="form-group">
                                        <label for="">Enter Regestered Mobile No</label>
                                        <input type="text" formControlName="phoneNo" class="form-control"
                                            placeholder="Please enter regestered mobile no"/>
                                        <div
                                            *ngIf="forgotPasswordForm.get('phoneNo').hasError('required') && forgotPasswordForm.get('phoneNo').touched">
                                            <span class="error">*Mobile Number is required.</span>
                                        </div>
                                        <span class="error">
                                            <p *ngIf="forgotPasswordForm.get('phoneNo').hasError('pattern') && forgotPasswordForm.get('phoneNo').dirty"
                                                class="error" padding>*Please enter valid Mobile Number.</p>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <label for="">Enter Registered Email ID</label>
                                        <input type="email" formControlName="email" class="form-control"
                                            placeholder="Please enter registered email id"  />
                                        <div
                                            *ngIf="forgotPasswordForm.get('email').hasError('required') && forgotPasswordForm.get('email').touched">
                                            <span class="error">*Email id is required.</span>
                                        </div>
                                        <span class="error">
                                            <p *ngIf="forgotPasswordForm.get('email').hasError('pattern') && forgotPasswordForm.get('email').dirty"
                                                class="error" padding>*Please enter valid Email id.</p>
                                        </span>
                                    </div>

                                    <div class="form-group text-center"> <button type="submit"
                                            class="btn btn-login btn-large width100 font-100 mr-2"
                                            (click)="forgotPassword()" style="background: black;width: 53%;">Send
                                            Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <footer class="footer">
            <p class="copyright">Copyright @ 2020 All Rights Reserved <strong class="theme-text-color"></strong>
            </p>
    </footer>
<!-------End forgot password ------>
