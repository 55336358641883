<!---- start view supplier quote ---->
<section>
  <div class="container1440 mt-5">
    <main class="middle-content">
      <div class="content-section">
        <div class="order-view mt30 mrgn-0-auto">
          <div>
            <form>
              <div class="form-group row">
                <label for="Sname" class="col-sm-2 col-form-label">Site Name</label>
                <div class="col-sm-4">
                  <label id="Sname" class="outup-label">{{bookingItems?.location|| 'NA'}}</label>
                </div>
                <label for="material" class="col-sm-2 col-form-label">Material</label>
                <div class="col-sm-4">
                  <label id="material" class="outup-label">{{bookingItems?.material|| 'NA'}}</label>
                </div>
              </div>
              <div class="form-group row">
                <label for="Mobile" class="col-sm-2 col-form-label">Weight</label>
                <div class="col-sm-4">
                  <label id="Mobile" class="outup-label">{{bookingItems?.weight|| 'NA'}}</label>
                </div>
                <label for="email" class="col-sm-2 col-form-label">Quote Date</label>
                <div class="col-sm-4">
                  <label id="email" class="outup-label">{{bookingItems?.createTime | date}}</label>
                </div>
              </div>

              <div class="form-group row">
                <label for="amount" class="col-sm-2 col-form-label">Amount</label>
                <div class="col-sm-4">
                  <label id="amount" class="outup-label">{{bookingItems?.bidAmount || 'NA'}}</label>
                </div>
                <label for="comanyAddress" class="col-sm-2 col-form-label">Drop Site</label>
                <div class="col-sm-4">
                  <label id="companyAddress" class="outup-label">{{bookingItems?.dropLocation|| 'NA' }}</label>
                </div>
              </div>
              <div class="form-group row">
                <label for="city" class="col-sm-2 col-form-label">PO Number</label>
                <div class="col-sm-4">
                  <label id="city" class="outup-label">{{bookingItems?.poNumber || 'NA'}}</label>
                </div>
              </div>
            </form>
          </div>
          <br>
          <div class="head_flt_select">
            <div class="head_flt_select input-label">
              <button routerLink='/list-of-supplier-quote' type="submit" class="btn  btn-theme">Close</button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</section>
<app-footer></app-footer>
<!---- End view supplier quote ---->
