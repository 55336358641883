<!------Start section of signup ---->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
<!-------Start search section and search by company name, location, state, city and mobile ------->
                                <div class="row">
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>Company Name</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="companyName">
                                            <option value="">Select Fleet Owner</option>
                                            <option *ngFor="let item of fleetOnwerCompanyNameArray"
                                                [value]="item?.companyName">{{item?.companyName}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>Location</label><br>
                                        <input type="text" id="Mobile" class="form-control selectBox"
                                            placeholder="Location" [(ngModel)]="siteLocation">
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>State</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="state"
                                            (change)="searchCity($event)">
                                            <option value="">State</option>
                                            <option *ngFor="let item of stateArr" [value]="item?.name">{{item?.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>City</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="city"
                                            [attr.disabled]="state ? null : '' ">
                                            <option value="">City</option>
                                            <option *ngFor="let item of cityArr" [value]="item?.name">{{item?.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>Mobile</label><br>
                                        <input type="text" class="form-control input-field" placeholder="Mobile"
                                            [(ngModel)]="phoneNo">
                                    </div>
                                    <div class="col-lg-2 pr-0 mt-4">
                                        <label>&nbsp;</label><br>
                                        <button type="button" class="search-btn" (click)="search()">Search</button>
                                    </div>
                                </div>
                                <div class="row  d-flex justify-content-end w_100 mt-3 mb-3">
                                    <i class='fas fa-file-csv mr-1' style='font-size:35px;color:green'
                                        (click)="exportToCsv()">
                                    </i>
                                    <button type="button" class="search-btn" (click)="reset()">Reset</button>
                                </div>
<!-------End search section and search by company name, location, state, city and mobile ------->

<!------Start list of signup data ----->
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Company Name</th>
                                                <th scope="col">User Type</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Mobile</th>
                                                <th scope="col">E-Mail</th>
                                                <th scope="col">City</th>
                                                <th scope="col">State</th>
                                                <th scope="col">Spl. Routes</th>
                                                <th scope="col">Material Name</th>
                                                <th scope="col">Pan no</th>
                                                <th scope="col">Aadhaar No</th>
                                                <th scope="col">GSTIN</th>
                                                <th scope="col">Date Of Creation</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let item of listing| paginate: { itemsPerPage: itemsPerPage,currentPage: currentPage,totalItems: totalItems };let i = index ">
                                                <td>{{item?.companyName || 'N/A'}}</td>
                                                <td>{{item?.role.role || 'N/A'}}</td>
                                                <td>{{item?.baseLocationAddress || 'N/A'}}</td>
                                                <td>{{item?.phoneNo || 'N/A'}}</td>
                                                <td>{{item?.email || 'N/A'}}</td>
                                                <td>{{item?.city || 'N/A'}}</td>
                                                <td>{{item?.state || 'N/A'}}</td>
                                                <td>{{item?.routes || 'N/A'}}</td>
                                                <td>{{item?.material || 'N/A'}}</td>
                                                <td>{{item?.panCardNo || 'N/A'}}</td>
                                                <td>{{item?.aadharCardNo || 'N/A'}}</td>
                                                <td>{{item?.gstInNo || 'N/A'}}</td>
                                                <td>{{item?.createTime | date:'medium' || 'N/A'}}</td>
                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <button class="btn-validate"
                                                        (click)="accept(item.role.role, item)">Accept</button>
                                                    <button class="btn-reject"
                                                        (click)="reject('DELETED', item.userId)">Reject</button>
                                                </td>
                                            </tr>

                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                            <tr>
                                                <td colspan="14" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
<!------End list of signup data ----->
<!-----Start pagination section for list of signup data ---->
                                <div *ngIf="totalItems > 10" class="custom-pagination mt20 "
                                    style="display: flex; justify-content:flex-end">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
<!-----End pagination section for list of signup data ---->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------End section of signup ---->
