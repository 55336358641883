<!--------Start view fleet owner billing ------->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">
                <div class="order-view mt30 mrgn-0-auto">

                    <div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Supplier Name :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.supplierName || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Fleet Owner Name :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.companyName || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Mobile" class="col-sm-2 col-form-label">Weight :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.weight || 'N/A'}}</label>
                            </div>
                            <label for="email" class="col-sm-2 col-form-label">Material :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.material || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">PO Number :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.poNumber || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Booking Date :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                                  class="  outup-label  col-form-label">{{billingData?.createTime | date:'medium' || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Amount :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.bidAmount || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Invoice Date :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                              class="outup-label  col-form-label">{{billingData?.inVoiceDateForCompany || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label"></label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label"></label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Invoice Number :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                              class="     outup-label   col-form-label">{{billingData?.inVoiceNoForCompany || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Vehicle Number :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.truckNumber || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Vehicle Type :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.truckType || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Driver Name :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.driverName || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Driver Mobile No :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                              class="   outup-label     col-form-label">{{billingData?.driverMobileNo || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Loaded Weight :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.loadedWeight || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Unloaded Weight :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.unLoadedWeight || 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Receiver Name :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.receiverName || 'N/A'}}</label>
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">Comments :</label>
                            <div class="col-sm-4">
                                <label for="Fname"
                              class=" outup-label  col-form-label">{{billingData?.comments || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Mobile" class="col-sm-2 col-form-label">Pick up Site :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.location || 'N/A'}}</label>
                            </div>
                            <label for="email" class="col-sm-2 col-form-label">Drop Site :</label>
                            <div class="col-sm-4">
                                <label for="Fname" class="outup-label col-form-label">{{billingData?.dropLocation || 'N/A'}}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="Fname" class="col-sm-2 col-form-label">Route Map :</label>
                            <div class="col-sm-4">
                                <img [src]="billingData?.routeMap || 'assets/img/dummy.png'" alt="" class="view-img">
                            </div>
                            <label for="Lname" class="col-sm-2 col-form-label">View Weight Slip :</label>
                            <div class="col-sm-4">
                                <img [src]="billingData?.uploadSlip|| 'assets/img/dummy.png'" alt="" class="view-img">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="Mobile" class="col-sm-2 col-form-label">View Selfie :</label>
                            <div class="col-sm-4">
                                <img [src]="billingData?.uploadSelfi || 'assets/img/dummy.png'" alt="" class="view-img">
                            </div>
                            <label for="email" class="col-sm-2 col-form-label">View Invoice :</label>
                            <div class="col-sm-4">
                                <img [src]="billingData?.viewInvoice || 'assets/img/dummy.png'" alt="" class="view-img">
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label" style="width: fit-content; margin: auto;">
                            <button type="submit" class="btn btn-theme" (click)="close()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!--------End view fleet owner billing ------->
