<!------ Start billing section in company management ----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
<!------- Start Search section and search by company name, invoice number, supplier, month, form date and to date -------->
                                <form [formGroup]="userForm">
                                    <div class="row">
                                        <div class="col-lg-2 pl-0 mt-4">
                                            <label>Company Name</label><br>
                                            <select id="CompanyName" class="form-control selectBox"
                                                formControlName="companyName">
                                                <option value="">Company name</option>
                                                <option *ngFor="let company of companyNameArr">{{company.name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-2 pl-0 mt-4">
                                            <label>Invoice No</label><br>
                                            <input class="selectBox form-control" type="text" placeholder="Invoice No"
                                                formControlName="invoiceNo">
                                        </div>
                                        <div class="col-lg-2 pl-0 mt-4">
                                            <label>Supplier</label><br>
                                            <select id="city" class="selectBox form-control"
                                                formControlName="supplierName" placeholder="Supplier">
                                                <option *ngFor="let supplier of supplierArr">{{supplier.name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-2 pl-0 mt-4">
                                            <label>Month</label><br>
                                            <select class="selectBox form-control" formControlName="month"
                                                placeholder="month">
                                                <option value="">Month</option>
                                                <option value="01">January</option>
                                                <option value="02">February</option>
                                                <option value="03">March</option>
                                                <option value="04">April</option>
                                                <option value="05">May</option>
                                                <option value="06">June</option>
                                                <option value="07">July</option>
                                                <option value="08">August</option>
                                                <option value="09">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-2 pl-0 mt-4">
                                            <label>From Date</label><br>
                                            <input type="date" class="selectBox form-control"
                                                formControlName="startdate">
                                        </div>
                                        <div class="col-lg-2 pl-0 mt-4">
                                            <label>To Date</label><br>
                                            <input type="date" class="selectBox form-control" formControlName="enddate">
                                        </div>
                                        <div class="col-lg-2 mt-3 p-0">
                                            <button type="button" class="search-btn" (click)="search()">Search</button>
                                        </div>
                                    </div>
                                    <div class="row  d-flex justify-content-end w_100 mt-3 mb-3">
                                        <i class='fas fa-file-csv mr-1' style='font-size:35px;color:green'
                                            (click)="ExportToCsv()"></i>
                                        <button type="button" class="search-btn" (click)="reset()">Reset</button>
                                    </div>
                                </form>
<!------- End Search section and search by company name, invoice number, supplier, month, form date and to date -------->

<!------- Start list of company billing ------->
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Company Name</th>
                                                <th scope="col">Invoice No</th>
                                                <th scope="col">Invoice Date</th>
                                                <th scope="col">Booking ID</th>
                                                <th scope="col">Supplier</th>
                                                <th scope="col">Material</th>
                                                <th scope="col">Weight</th>
                                                <th scope="col">Delivery Date</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">PO Number</th>
                                                <th scope="col">Vehicle Number</th>
                                                <th scope="col">Vehicle Type</th>
                                                <th scope="col">Driver Name</th>
                                                <th scope="col">Driver Mobile</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let item of listing | paginate: { itemsPerPage: itemsPerPage,currentPage: currentPage,totalItems: totalRecords };let i = index ">
                                                <td>{{item?.companyName}}</td>
                                                <td>{{item?.inVoiceNoForCompany}}</td>
                                                <td>{{item?.inVoiceDateForCompany | date:'medium'}}</td>
                                                <td>{{item?.bookingId}}</td>
                                                <td>{{item?.supplierName}}</td>
                                                <td>{{item?.material}}</td>
                                                <td>{{item?.weight}}</td>
                                                <td>{{item?.deliveryDate | date:'short'}}</td>
                                                <td>{{item?.location}}</td>
                                                <td>Rs.{{item?.bidAmount}}</td>
                                                <td>{{item?.poNumber}}</td>
                                                <td>{{item?.truckNumber}}</td>
                                                <td>{{item?.truckType}}</td>
                                                <td>{{item?.driverName}}</td>
                                                <td>{{item?.driverMobileNo}}</td>
                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <a tooltip="View" (click)="billingDetails(item?.bookingId)"><i class="fa fa-eye"
                                                            aria-hidden="true"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                            <tr>
                                                <td colspan="13" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
<!------- End list of company billing ------->

<!--------Start pagination section for list of company billing------->
                                <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                    *ngIf="listing?.length">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
<!--------End pagination section for list of company billing------->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------ End billing section in company management ----->

