<!-------Start section of truck type ------>
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
<!------Start add truck type and decription ----->
                                <form [formGroup]="addTruckForm">
                                    <div class="row  d-flex justify-content-end w_100 mt-3">
                                        <div class="col-lg-3 pr-0">
                                            <input class="selectBox form-control" type="text" placeholder="Truck Type"
                                                formControlName="typeOfTruck" maxlength="60">
                                            <span class="error"
                                                *ngIf="addTruckForm.get('typeOfTruck').hasError('required') && (addTruckForm.get('typeOfTruck').dirty || addTruckForm.get('typeOfTruck').touched )"
                                                class="error">
                                                <p>*Please enter truck type.</p>
                                            </span>
                                        </div>
                                        <div class="col-lg-6 ">
                                            <input class="selectBox form-control" type="text" placeholder="Description"
                                                formControlName="description">
                                            <span class="error"
                                                *ngIf="addTruckForm.get('description').hasError('required') && (addTruckForm.get('description').dirty || addTruckForm.get('description').touched )"
                                                class="error">
                                                <p>*Please enter description.</p>
                                            </span>
                                        </div>
                                        <div class="col-lg-3 text-left pl-0">
                                            <button type="button" class="search-btn" [disabled]="addTruckForm.invalid"
                                                (click)="addTruckType()">Save</button>
                                        </div>
                                    </div>
                                </form>
<!------End add truck type and decription ----->

<!------Start list of truck type  ----->
                                <div class="table-responsive mt-3">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">S. No</th>
                                                <th scope="col">Truck Type</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let item of listing| paginate: { itemsPerPage: itemsPerPage,currentPage: currentPage,totalItems: totalItems };let i = index ">
                                                <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                                <td>{{item.typeOfTruck}}</td>
                                                <td>{{item.description}}</td>
                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <a tooltip="Delete" (click)="openModal('DELETE',item?.truckTypeId)">
                                                        <i class="fa fa-trash" aria-hidden="true"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                            <tr>
                                                <td colspan="13" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
<!------End list of truck type  ----->

<!------Start pagination for list of truck type  ----->
                                <div *ngIf="totalItems > 10" class="custom-pagination mt20 "
                                    style="display: flex; justify-content:flex-end">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
<!------End pagination for list of truck type  ----->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!-------Start section of truck type ------>

<!--  Start delete modal-->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Delete Truck Type </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to delete this truck type?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()"
                                        data-dismiss="modal"
                                        style="min-width:100px;background-color: black;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="min-width: 100px;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!--  End delete modal-->