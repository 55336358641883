<!-----Start view company ------>
<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="overflow-y: scroll; height: -webkit-fill-available;">
            <div class="content-section">             
                <div class="order-view mt30 mrgn-0-auto">
                    <div>
                        <form [formGroup]="viewCompanyForm">
                            <div class="form-group row">
                                <label for="Fname" class="col-sm-2 col-form-label">First Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Fname" class="form-control" placeholder="First name"
                                        formControlName="firstName" disabled>
                                </div>
                                <label for="Lname" class="col-sm-2 col-form-label">Last Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Lname" class="form-control" placeholder="Last name"
                                        formControlName="lastName" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Mobile" class="col-sm-2 col-form-label">Mobile No</label>
                                <div class="col-sm-4">
                                    <input type="text" id="Mobile" class="form-control"
                                        placeholder="Enter mobile number" formControlName="phoneNo" disabled>
                                </div>

                                <label for="email" class="col-sm-2 col-form-label">Email ID</label>
                                <div class="col-sm-4">
                                    <input type="text" id="email" class="form-control" placeholder="Enter mail Id"
                                        formControlName="email" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="CompanyName" class="col-sm-2 col-form-label">Company Name</label>
                                <div class="col-sm-4">
                                    <input type="text" id="CompanyName" class="form-control" placeholder="Company name"
                                        formControlName="companyName" disabled>
                                </div>
                                <label for="comanyAddress" class="col-sm-2 col-form-label">Company Address</label>
                                <div class="col-sm-4">
                                    <input type="text" id="comanyAddress" class="form-control"
                                        placeholder="Company address" formControlName="companyAddress" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="state" class="col-sm-2 col-form-label">State</label>
                                <div class="col-sm-4">
                                    <input type="text" id="state" class="form-control"
                                    placeholder="State" formControlName="state" disabled>

                                </div>
                                <label for="city" class="col-sm-2 col-form-label">City</label>
                                <div class="col-sm-4">
                                    <input type="text" id="city" class="form-control"
                                    placeholder="City" formControlName="city" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="aadhar" class="col-sm-2 col-form-label">Aadhar No</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="aadhar" class="form-control" placeholder="Enter aadhar number"
                                        style="border: 1px solid;" formControlName="aadhaarNo"  maxlength="12" disabled>
                                </div>

                                <label for="pan" class="col-sm-2 col-form-label">Pan Card</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="pan" class="form-control" placeholder="Enter PAN number"
                                        style="border: 1px solid;" formControlName="panCard" maxlength="10" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4">
                                    <img [src]="aadharimageUrl" alt="">
                                </div>                               
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4">
                                    <img [src]="panimageUrl" alt="">
                                </div>                               
                            </div>
                            <div class="form-group row">
                                <label for="gst" class="col-sm-2 col-form-label">GISTN No</label>
                                <div class="col-sm-4 input-group">
                                    <input type="text" id="gst" class="form-control" placeholder="Enter GISTN no"
                                        style="border: 1px solid;" formControlName="gstNo" maxlength="16" disabled>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4">
                                    <img [src]="gstimageUrl" alt=""> 
                                </div>                              
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label">
                            <button type="submit" class="btn btn-theme mr-3"
                            (click)="back()">Back</button>
                            <button type="submit" class="btn btn-theme"
                                (click)="edit()">EDIT</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!-----End view company ------>
