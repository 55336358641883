<!------ Start fleet owner billing section in supplier management ----->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
 <!-------Start search section and search by booking id, supplier, month, from date and to date ------>                               
                                <div class="row">
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label>Fleet Owner</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="fleetOwner">
                                            <option value="">Select Fleet Owner</option>
                                            <option *ngFor="let item of fleetOnwerCompanyNameArray"
                                                [value]="item?.userId">{{item?.companyName}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label class="ml-3">Invoice</label><br>
                                        <input type="text" (keypress)="service.numberOnly($event)" class="form-control"
                                            style="margin: 1px;" [(ngModel)]="inVoiceNoForSupplier"
                                            placeholder="Invoice No">
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label class="ml-3">Supplier</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="supplierName">
                                            <option value="">Supplier</option>
                                            <option *ngFor="let item of supplierNameArray" [value]="item?.userId">
                                                {{item?.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label class="ml-2">Month</label><br>
                                        <select class="selectBox form-control" [(ngModel)]="months">
                                            <option value="">Month</option>
                                            <option *ngFor="let item of monthsArray" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label class="ml-2">From Date</label><br>
                                        <input type="date" max="{{minAge | date:'yyyy-MM-dd'}}" name="formdate"
                                            [(ngModel)]="calender.formdate" (change)="formdate()" class="form-control">
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4">
                                        <label class="ml-2">To Date</label><br>
                                        <input type="date" name="todate" min="{{fromDate | date:'yyyy-MM-dd'}}"
                                            (change)="todate()" [disabled]="!calender.formdate"
                                            [(ngModel)]="calender.todate" class="form-control">
                                    </div>

                                </div>
                                <div class="row  d-flex justify-content-end w_100 mt-3 mb-3">
                                    <i class='fas fa-file-csv mr-1' style='font-size:35px;color:green; cursor: pointer;'
                                        (click)="ExportToCsv()" aria-hidden="true"></i>
                                    <button type="button" class="search-btn" (click)="search()">Search</button>
                                    <button type="button" class="search-btn" (click)="reset()">Reset</button>
                                </div>
 <!-------End search section and search by booking id, supplier, month, from date and to date ------>                               
 <!-------Start list of fleet owner billing supplier ------>                               
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Invoice No</th>
                                                <th scope="col">Invoice Date</th>
                                                <th scope="col">Booking ID</th>
                                                <th scope="col">Fleet Owner</th>
                                                <th scope="col">Supplier</th>
                                                <th scope="col">Material</th>
                                                <th scope="col">Weight</th>
                                                <th scope="col">Delivery Date</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">PO Number</th>
                                                <th scope="col">Vehicle Number</th>
                                                <th scope="col">Vehicle Type</th>
                                                <th scope="col">Driver Name</th>
                                                <th scope="col">Driver Mobile</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let item of listing| paginate: { itemsPerPage: itemsPerPage,currentPage: currentPage,totalItems: totalItems };let i = index ">
                                                <td>{{item?.inVoiceNoForSupplier || 'NA'}}</td>
                                                <td>{{item?.inVoiceDateForSupplier |date}}</td>
                                                <td>{{item?.bookingId || 'NA'}}</td>
                                                <td>{{item?.companyName|| 'N/A'}}</td>
                                                <td>{{item?.supplierName || 'NA'}}</td>
                                                <td>{{item?.material || 'NA'}}</td>
                                                <td>{{item?.weight || 'NA'}}</td>
                                                <td>{{item?.deliveryDate | date}}</td>
                                                <td>{{item?.location || 'NA'}}</td>
                                                <td>{{item?.bidAmount || 'NA'}}</td>
                                                <td>{{item?.poNumber || 'NA'}}</td>
                                                <td>{{item?.truckNumber || 'NA'}}</td>
                                                <td>{{item?.truckType || 'NA'}}</td>
                                                <td>{{item?.driverName || 'NA'}}</td>
                                                <td>{{item?.driverMobileNo || 'NA'}}</td>
                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <a tooltip="View"
                                                        [routerLink]="['/view-fleet-owner-billing-supplier',item?.bookingId]"><i
                                                            class="fa fa-eye" aria-hidden="true"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                            <tr>
                                                <td colspan="13" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
 <!-------Start list of fleet owner billing supplier ------>  

 <!-------Start pagination for list of fleet owner billing supplier ------>                               
                                <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                    *ngIf="totalItems > 10">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
 <!-------End pagination for list of fleet owner billing supplier ------>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!------ End fleet owner billing section in supplier management ----->
