<!--------Start company section in company management------>
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
                                <form [formGroup]="userForm">
<!------- Start Search section and search by company name, location, state, city, mobile -------->
                                <div class="row">
                                    <div class="col-lg-2 mt-4 pl-0">
                                        <label>Company Name</label><br>
                                        <select id="CompanyName" class="form-control selectBox"  formControlName="companyName">
                                            <option value="">Company name</option>
                                            <option *ngFor="let company of companyNameArr" >{{company.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 mt-4 pl-0">
                                        <label>Location</label><br>
                                        <input type="text" id="Mobile" class="form-control selectBox"
                                        placeholder="Location" formControlName="location" >
                                    </div>
                                    <div class="col-lg-2 mt-4 pl-0">
                                        <label>State</label><br>
                                        <select class="form-control selectBox" (change)="searchCity($event)" formControlName="state">
                                            <option value="State">State</option>
                                            <option *ngFor="let state of stateArr">{{state.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 mt-4 pl-0">
                                        <label>City</label><br>
                                        <select class="form-control selectBox" formControlName="city">
                                            <option value="city">City</option>
                                            <option *ngFor="let city of cityArr">{{city.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 pl-0 mt-4 ">
                                        <label>Mobile</label><br>
                                        <input type="text" id="Mobile" class="form-control selectBox"
                                        placeholder="Mobile" formControlName="phoneNo" maxlength="13">
                                        <span class="error"
                                        *ngIf="userForm.get('phoneNo').hasError('pattern') && userForm.get('phoneNo').dirty"
                                        class="error">
                                        <p> *Please enter valid mobile number.</p>
                                    </span>
                                    </div>
                                    <div class="col-lg-2 mt-4 p-0">
                                        <label>&nbsp;</label><br>
                                        <button type="button" class="search-btn" (click)="search()">Search</button>
                                    </div>
                                </div>
                                <div class="row  d-flex justify-content-end w_100 mt-3 mb-3">
                                    <i class='fas fa-file-csv mr-1' style='font-size:35px;color:green'
                                        (click)="ExportToCsv()"></i>
                                    <button type="button" class="search-btn" (click)="reset()">Reset</button>
                                    <button type="button" class="btn-add" (click)="addCompany()">Add</button>
                                </div>
                                </form>
<!------- Start Search section and search by company name, location, state, city, mobile -------->
<!------- Start list of company  ------->
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Company Name</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Mobile</th>
                                                <th scope="col">E-Mail</th>
                                                <th scope="col">City</th>
                                                <th scope="col">State</th>
                                                <th scope="col">GSTIN</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Date Of Creation</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of listing| paginate: { itemsPerPage: itemsPerPage,currentPage: currentPage,totalItems: totalRecords };let i = index ">
                                                <td>{{item?.companyName}}</td>
                                                <td>{{item?.baseLocationAddress}}</td>
                                                <td>{{item?.phoneNo}}</td>
                                                <td>{{item?.email}}</td>
                                                <td>{{item?.city}}</td>
                                                <td>{{item?.state}}</td>
                                                <td>{{item?.gstInNo}}</td>
                                                <td>{{item?.userStatus}}</td>
                                                <td>{{item?.createTime | date:'short'}}</td>
                                                <td class="action_td_btn3" style="cursor: pointer">
                                                    <a tooltip="View"><i class="fa fa-eye" (click)="viewCompany(item?.userId)"></i></a>
                                                    <a tooltip="Delete"><i class="fa fa-trash" (click)="deleteCompany(item?.userId)"></i></a>
                                                    <a *ngIf="item?.userStatus == 'ACTIVE'" tooltip="Block" (click)="openModal('BLOCK',item?.userId)"><i class="fa fa-ban" style="color: green;"></i></a>
                                                    <a *ngIf="item?.userStatus == 'BLOCK'" tooltip="Active" (click)="openModal('ACTIVE',item?.userId)"><i class="fa fa-ban" style="color: red;"></i></a>
                                                    <a tooltip="Reset Password"><i class="fa fa-unlock-alt" (click)="resetPassword(item?.userId, item?.phoneNo, item?.email)"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="listing?.length==0">
                                            <tr>
                                                <td colspan="13" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 44%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
<!------- End list of company  ------->
<!--------Start pagination section for list of company ------>
                                <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                    *ngIf="listing?.length">
                                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                </div>
 <!--------End pagination section for list of company ------>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</section>
<app-footer></app-footer>
<!--------End company section in company management------>

<!-- Start block modal-->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Block Company </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to block this company?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 "
                                        style="width: 25%;background-color: black;" (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End block modal-->

<!-- Start active modal-->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Active Company </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to active this company?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End active modal-->
